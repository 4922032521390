import React from 'react';
import { Link } from 'react-router-dom';
import { BLOG } from '../../../routes/CONSTANTS';
import { blogPosts } from '../../../utils';

const OurBlog = () => {
  const randomBlogPosts = blogPosts.sort(() => 0.5 - Math.random()).slice(0, 3);

  return (
    <div className={`$ py-16 px-3 sm:px-12 lg:px-56 `}>
      <h1 className='text-center font-bold text-2xl md:text-3xl text-blue-600'>
        OUR <span className='text-red'>BLOG</span>
      </h1>
      <p className='text-center text-xl pb-12 pt-4 text-blue-600'>
        Stay Updated With Our <span className='text-red'>Blog</span>
      </p>
      <div className=' grid grid-cols-1 lg:grid-cols-3 md:gap-6 gap-12'>
        {randomBlogPosts.map(({ id, title, description, link, tag, date }) => (
          <div
            key={id}
            className='bg-white rounded-xl shadow-lg p-4 text-base flex flex-col gap-4 cursor-pointer border-gray-400 border-[0.5px] hover:translate-y-2 transition-all duration-300'
          >
            <div className='bg-red text-white p-2 w-16 rounded-sm'>Blog</div>
            <h3 className='font-semibold text-blue-600 text-xl text-center'>
              {title}
            </h3>
            <p className='text-center'>
              {description}
              <span className='hover:text-blue text-red'>
                <Link to={link}>Read More</Link>
              </span>
            </p>
            <div className='flex items-center text-sm gap-2 justify-center'>
              <p className='text-red'>{tag}</p>
              <div className='h-4 w-[0.5px] bg-blue'></div>
              <p className='text-blue-600'>{date}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='text-center pt-12 w-full'>
        <Link
          to={BLOG}
          className='bg-red hover:bg-blue transition-all duration-300 rounded-sm p-3 text-white w-full lg:w-1/4
        '
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default OurBlog;
