const IMAGES_PATH = '/assets/images';

// Logo
export const logoBlack = `${IMAGES_PATH}/LOGO-BLACK.png`;
export const logoGray = `${IMAGES_PATH}/LOGO-GRAY.png`;
export const logoRed = `${IMAGES_PATH}/LOGO-RED.png`;
export const logoWhite = `${IMAGES_PATH}/LOGO-WHITE.png`;

//
export const mapGray = `${IMAGES_PATH}/map-gray.png`;
export const factory = `${IMAGES_PATH}/factory.webp`;
export const manufacturing = `${IMAGES_PATH}/manufacturing.webp`;

// contact
export const contact = `${IMAGES_PATH}/contact.jpg`;

// Partners
export const zim = `${IMAGES_PATH}/zim.png`;
export const dangote = `${IMAGES_PATH}/dangote.png`;
export const hapag = `${IMAGES_PATH}/hapag.png`;
export const bajaj = `${IMAGES_PATH}/bajaj.png`;
export const mrs = `${IMAGES_PATH}/mrs.jpg`;
export const joatelim = `${IMAGES_PATH}/joatelim1.png`;

// Markets
export const markets = `${IMAGES_PATH}/markets.jpg`;

export const why = `${IMAGES_PATH}/why.jpg`;
export const leadership = `${IMAGES_PATH}/leadership.jpg`;

export const businessjpg = `${IMAGES_PATH}/businessjpg.jpg`;
export const scs = `${IMAGES_PATH}/scs.jpg`;

//sustainability
export const sustainability = `${IMAGES_PATH}/sustainability.png`;

export const corporategovernance = `${IMAGES_PATH}/corporategovernance.webp`;
export const responsibleemployer = `${IMAGES_PATH}/responsibleemployer.webp`;
export const innovator = `${IMAGES_PATH}/innovator.webp`;
export const sustainabilityHero = `${IMAGES_PATH}/sustainability.jpg`;

// manufacturing
export const tomatoProcessing = `${IMAGES_PATH}/tomato-processing.jpg`;
export const shoeManufacturing = `${IMAGES_PATH}/shoe-manufacturing.webp`;
export const solarManufacturing = `${IMAGES_PATH}/solar-manufacturing.webp`;
export const plasticManufacturing = `${IMAGES_PATH}/plastic-manufacturing.webp`;
export const recycling = `${IMAGES_PATH}/recycling.jpg`;
export const busmanufacturing = `${IMAGES_PATH}/busmanufacturing.jpg`;

// supply chain
export const terminal = `${IMAGES_PATH}/terminal.jpg`;
export const vessel = `${IMAGES_PATH}/vessel.jpg`;
export const trucking = `${IMAGES_PATH}/trucking.jpg`;
export const importExport = `${IMAGES_PATH}/import.jpg`;
export const supplychainservices = `${IMAGES_PATH}/supplychainservices.jpg`;

// sustaining services
export const sustaininservices = `${IMAGES_PATH}/sustaininservices.png`;

//oil and gas
export const oilandgas = `${IMAGES_PATH}/oilandgas.png`;

// ditribution mgt
export const grayBg = `${IMAGES_PATH}/gray-bg.jpg`;
export const handshake = `${IMAGES_PATH}/handshake.webp`;

export const bulb = `${IMAGES_PATH}/bulb.webp`;

export const mgt = `${IMAGES_PATH}/mgt.jpg`;
export const portrait = `${IMAGES_PATH}/portrait.jpg`;

// privacy policy and Terms and conditions
export const privacy = `${IMAGES_PATH}/privacy.jpg`;
export const terms = `${IMAGES_PATH}/terms.jpg`;

// contact
export const contactus = `${IMAGES_PATH}/contactus.jpg`;

//foundation
export const foundation = `${IMAGES_PATH}/foundation.jpg`;
