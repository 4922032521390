import React from 'react';
import {
  ManufacturingHero,
  ManufacturingMarkets,
  ManufacturingSolutions,
  Placements,
  Tag,
} from '../../components/layout/Manufacturing';
import { Map } from '../../components/layout/Home';
import { ContactForm } from '../../components/layout/Contact';

const ManufacturingView = () => {
  return (
    <div>
      <ManufacturingHero />
      <Tag />
      <ManufacturingMarkets />
      <ManufacturingSolutions />
      <Placements />
      <Map />
      <ContactForm />
    </div>
  );
};

export default ManufacturingView;
