import React from 'react';
import { manufacturing } from '../../../assets/images';

const DistributionMgtHero = () => {
  return (
    <div>
      <header
        className='h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${manufacturing})` }}
      >
        <div className='bg-black bg-opacity-80 w-screen h-[80vh] flex flex-col gap-8 justify-center px-6 sm:px-8 md:px-12 xl:px-56 '>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl  md:leading-tight'>
            Distribution Management Solutions
          </div>
          <p className='text-lg text-white'>
            Partner with Tatabe to manage—and simplify—the complexities of
            distribution, logistics, order fulfillment and inventories for
            product servicing.
          </p>
        </div>
      </header>
    </div>
  );
};

export default DistributionMgtHero;
