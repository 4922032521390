import React from 'react';
import { terms } from '../../../assets/images';

const TermsHero = () => {
  return (
    <div>
      <header
        className='h-[70vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${terms})` }}
      >
        <div className='bg-black bg-opacity-70 w-screen h-[70vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            TERMS AND CONDITIONS
          </div>
        </div>
      </header>
    </div>
  );
};

export default TermsHero;
