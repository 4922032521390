import React from 'react';

const WhyHero = () => {
  return (
    <div className='pattern h-[60vh] sm:py-32 py-16 px-3 sm:px-12 lg:px-24 grid '>
      <div className='text-blue flex flex-col gap-6 justify-center'>
        <h1 className='text-4xl md:text-6xl font-bold text-blue-600'>
          Contact Us
        </h1>
        <p className='text-lg sm:text-2xl text-black w-full sm:w-1/2'>
          We'd love to hear from you. Have a question about{' '}
          <span className='text-red'>Tatabe</span>? Need to talk to customer
          service? Get in touch with us
        </p>
      </div>
    </div>
  );
};

export default WhyHero;