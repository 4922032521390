import React from 'react';
import { handshake, mapGray } from '../../../assets/images';

const DistributionSupport = () => {
  return (
    <div className='px-3 py-16 sm:px-12 xl:px-56 bg-gray-400 flex flex-col gap-36'>
      <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-6 '>
        <div className='flex flex-col gap-8'>
          <p className='text-3xl md:text-4xl font-semibold'>
            Global support for your distribution management needs.
          </p>
          <div className='md:hidden block'>
            <img src={mapGray} alt='' className='md:h-[40vh]' />
          </div>
          <p className='text-lg text-center md:text-left'>
            Tatabe provides distribution management services on a global scale.
            Strategically located facilities allow us to tailor a solution to
            your product and process.
          </p>
        </div>
        <div className='hidden md:block'>
          <img src={mapGray} alt='' className='md:h-[40vh]' />
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-6 '>
        <div className='hidden md:block border-l-[16px] border-l-[#A0B350] '>
          <img src={handshake} alt='' className='md:h-[40vh] opacity-60' />
        </div>
        <div className='flex flex-col gap-8'>
          <p className='text-2xl md:text-4xl font-semibold'>
            Deliver on your promise to customers. Every time.
          </p>
          <div className='md:hidden block border-l-[16px] border-l-[#A0B350] '>
          <img src={handshake} alt='' className='md:h-[40vh] opacity-60' />
        </div>
          <p className='text-lg text-center md:text-left'>
            Free up your own team’s time and resources. Optimize costs. Enhance
            your customer experience. With every product, you’re making a
            promise to your customers. You can rely on us to help you keep it.
          </p>
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-6 '>
        <div className='flex flex-col gap-8'>
          <p className='text-2xl md:text-4xl font-semibold'>
            Enabling the circular economy with smart distribution management
            solutions.
          </p>
          <div className='md:hidden block'>
            <img src={mapGray} alt='' className='md:h-[40vh]' />
          </div>
          <p className='text-lg text-center md:text-left'>
            Integrated service solutions mean greater efficiency. Our Sustaining
            Services help you streamline logistics, inventory and
            fulfillment—reducing waste and enabling smart management of
            resources. It’s part of our commitment to sustainability. And how we
            can build a healthier world together.
          </p>
          <button className='rounded-sm bg-[#A0B350] hover:bg-[#0f1725] text-white p-3 w-full xl:w-1/2'>
            Our Commitment To Sustainbaility
          </button>
        </div>
        <div className='hidden md:block'>
          <img src={mapGray} alt='' className='md:h-[40vh]' />
        </div>
      </div>
    </div>
  );
};

export default DistributionSupport;
