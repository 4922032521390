import React from 'react';

const Values = () => {
  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-3 text-lg h-full'>
        <div className=' bg-[#0f1725] text-white p-2 lg:p-8 flex flex-col gap-2 text-center'>
          <p className='font-semibold text-2xl '>OUR VALUES</p>
          <p className='font-semibold'>Guiding Principles</p>
          <p className='mt-4'>
            At Tatabe Global Services Ltd, our values are not just words on
            paper; they are the guiding principles that define our culture and
            influence our actions. Integrity
          </p>
          <p className='font-semibold mt-2'>Unwavering Ethics</p>
          <p>
            Integrity is the bedrock of our organization. We conduct our
            business with the highest ethical standards, ensuring trust and
            transparency in every interaction. Our commitment to honesty and
            fairness is unwavering, fostering strong relationships with our
            clients, partners, and employees.
          </p>
          <p className='font-semibold mt-2'>Innovation</p>
          <p>Pioneering Solutions</p>
          <p>
            Innovation is at the heart of what we do. We encourage creative
            thinking and embrace emerging technologies to continuously improve
            our services. By staying at the forefront of industry advancements,
            we provide our clients with pioneering solutions that drive growth
            and set new industry standards.
          </p>
        </div>
        <div className=' p-2 lg:p-8 flex flex-col gap-2 text-center text-[#0f1725] patterngray'>
          <p className='font-semibold text-2xl '>OUR VISION</p>
          <p>Shaping a Brighter Future</p>
          <p className='mt-4'>
            Our vision is to be a global leader in the Oil and Gas industry,
            Supply chain industry and the manufacturing industry, setting the
            standard for excellence and sustainability in every industry we
            touch. We aspire to shape a brighter future by continuously
            innovating, expanding our horizons, and fostering partnerships that
            drive progress. We envision a world where responsible business
            practices and client-centric solutions are at the forefront of
            industry norms.
          </p>
        </div>
        <div className=' bg-red text-white p-2 lg:p-8 flex flex-col gap-2 text-center'>
          <p className='font-semibold text-2xl '>OUR MISSION</p>
          <p>Empowering Progress, Sustaining Excellence</p>
          <p className='mt-4'>
            At Tatabe Global Services Ltd, our mission is to empower progress by
            providing integrated services that drive growth and development
            across diverse industries. We are committed to delivering innovative
            solutions that not only meet but exceed the evolving needs of our
            clients. Our unwavering dedication to sustainability and responsible
            business practices underpins our every action, ensuring that we
            contribute positively to society and the environment.
          </p>
        </div>
      </div>

      <div className='pattern py-16'>
        <h2 className='font-semibold text-3xl md:text-5xl text-[#0f1725] text-center pb-10'>
          OUR GUIDING <span className='text-red'>PRINCIPLES</span>
        </h2>
        <div className=' grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-6 px-3 sm:px-12 lg:px-32 text-lg'>
          <div className='bg-white rounded-md p-4 shadow-xl'>
            <p className='text-red font-semibold mb-4 text-2xl'>Safety</p>
            <p className='text-[#0f1725]'>
              The safety of our employees, clients, and the environment is our
              top priority. We maintain rigorous safety protocols across all our
              operations, adhering to international standards and best
              practices. Our goal is to protect the well-being of all
              stakeholders and minimize our environmental footprint.
            </p>
          </div>
          <div className='bg-white rounded-md p-4 shadow-xl'>
            <p className='text-red font-semibold mb-4 text-2xl'>
              Sustainability
            </p>
            <p className='text-[#0f1725]'>
              Sustainability is not just a buzzword; it's a fundamental
              commitment. We are dedicated to environmental sustainability and
              responsible business practices. Through eco-friendly initiatives,
              resource optimization, and community engagement, we aim to leave a
              positive legacy for future generations.
            </p>
          </div>
          <div className='bg-white rounded-md p-4 shadow-2xl'>
            <p className='text-red font-semibold mb-4 text-2xl'>
              Customer-Centric
            </p>
            <p className='text-[#0f1725]'>
              Our clients are the driving force behind everything we do. We
              place their needs at the center of our operations and go the extra
              mile to exceed their expectations. Your success is our mission,
              and we are committed to delivering value, reliability, and
              exceptional service in every partnership
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
