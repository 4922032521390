import React from 'react';
import DistributionManagementView from './DistributionManagementView';
import { Wrapper } from '../../components/modules/wrapper';

export const DistributionManagementContainer = () => {
  return (
    <Wrapper>
      <DistributionManagementView />
    </Wrapper>
  );
};
