import React from 'react';
import { foundation } from '../../../assets/images';

const Foundation = () => {
  return (
    <div>
      <header
        className='h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex w-full items-center md:px-24 px-1'
        style={{ backgroundImage: `url(${foundation})` }}
      >
        <div className='bg-white bg-opacity-95 w-screen lg:w-[40%] h-[70vh] flex flex-col gap-8 px-6 sm:px-8 justify-center rounded-md '>
          <div className='text-red font-bold md:font-semibold text-3xl md:text-4xl  lg:leading-tight'>
            TATABE <span className='text-blue-600'>FOUNDATION</span>
          </div>
          <p className='font-semibold text-3xl text-blue-600'>Impacting <span className="text-red">Lives</span></p>
          <p className='text-base'>
            Since its incorporation, the Ali Tatabe Foundation has worked hard
            to enhance opportunities for social change through strategic
            investments that improve health and wellbeing, promote quality
            education, and broaden economic empowerment opportunities.
          </p>
          <p className='font-bold text-lg'>
            <span className='text-yellow'>Empowerment, </span>{' '}
            <span className='text-blue-200'>Relief, </span>{' '}
            <span className='text-green-100'>Health</span>
          </p>
          <button className='bg-red p-2 w-1/3 rounded-sm text-white hover:bg-blue'>
            Read More
          </button>
        </div>
      </header>
    </div>
  );
};

export default Foundation;
