import React from 'react';
import AboutView from './AboutView';
import { Wrapper } from '../../components/modules/wrapper';

export const AboutContainer = () => {
  return (
    <Wrapper>
      <AboutView />
    </Wrapper>
  );
};
