import React from 'react';
import { BlogHero, BlogLayout } from '../../components/layout/Blog';

const BlogView = () => {
  return (
    <div>
      <BlogHero />
      <BlogLayout />
    </div>
  );
};

export default BlogView;
