import React from 'react';
import { privacy } from '../../../assets/images';

const PrivacyHero = () => {
  return (
    <div>
      <header
        className='h-[70vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${privacy})` }}
      >
        <div className='bg-[#0f1725] bg-opacity-70 w-screen h-[70vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            PRIVACY POLICY
          </div>
          <div>
            <h2 className="text-red text-2xl">Privacy Policy for Tatabe Global Services Ltd.</h2>
            <p>Effective Date: [Insert Date]</p>
          </div>
        </div>
      </header>
    </div>
  );
};

export default PrivacyHero;
