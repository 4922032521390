import React from 'react';
import { Terms, TermsHero } from '../../components/layout/Terms';

const TermsView = () => {
  return (
    <div>
      <TermsHero />
      <Terms />
    </div>
  );
};

export default TermsView;
