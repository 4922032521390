import React from 'react';
import { scs } from '../../../assets/images';

const BusinessPractices = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 px-3 sm:px-12 xl:px-56 py-16 items-center text-lg md:text-left text-center'>
      <div className='flex flex-col gap-6'>
        <h3 className='font-semibold text-3xl md:text-4xl'>
          Ensuring a higher standard of business practices.
        </h3>
        <div className='block md:hidden'>
          <img src={scs} alt='' />
        </div>
        <p className=''>
          Tatabe is a member of the Responsible Business Alliance (RBA®) because
          we believe it’s our responsibility to take an active role in
          safeguarding our communities, our team members and our long-term
          business success. Every day is another opportunity for us to ask,
          “What can we do today for an even better tomorrow?” It’s a chance to
          empower every teammate to do what’s right and protect the well-being
          of the environment, our business and those around us.
        </p>
        <div className='text-center md:text-left'>
          <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full  text-sm md:text-base'>
            LEARN MORE ABOUT RESPONSIBLE BUSINESS PRACTICES
          </button>
        </div>
      </div>
      <div className='md:block hidden'>
        <img src={scs} alt='' />
      </div>
    </div>
  );
};

export default BusinessPractices;
