import React, { useState, useEffect } from 'react';
import { next, prev } from '../../../assets/icons';

const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div className='relative'>
      <div className='overflow-hidden'>
        <div
          className='flex transition-transform duration-1000 ease-in-out transform'
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className='w-full flex-shrink-0 px-5 sm:px-12 xl:px-64 text-center'
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
      <div className=' absolute left-1/2 transform -translate-x-1/2 flex gap-4 mt-6'>
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-4 h-4 rounded-full ${
              index === currentSlide ? 'bg-red' : 'bg-white'
            }`}
          ></button>
        ))}
      </div>
      <button
        onClick={goToPrevSlide}
        className='absolute top-1/2 left-24 transform -translate-y-1/2 hidden md:block'
      >
        <img src={prev} alt='previous' />
      </button>
      <button
        onClick={goToNextSlide}
        className='absolute top-1/2 right-24 transform -translate-y-1/2 hidden md:block'
      >
        <img src={next} alt='next' />
      </button>
    </div>
  );
};

const ValuesCarousel = () => {
  const slides = [
    <div className='text-white flex flex-col gap-6'>
      <p className='font-bold text-red text-2xl md:text-3xl mt-2'>
        Guiding Principles
      </p>
      <p className='text-lg sm:text-xl'>
        At Tatabe Global Services Ltd, our values are not just words on paper;
        they are the guiding principles that define our culture and influence
        our actions. Integrity
      </p>
    </div>,
    <div className='text-white flex flex-col gap-6'>
      <p className=' font-bold text-red mt-2 text-2xl md:text-3xl'>
        Unwavering Ethics
      </p>
      <p className='text-lg sm:text-xl'>
        {' '}
        Integrity is the bedrock of our organization. We conduct our business
        with the highest ethical standards, ensuring trust and transparency in
        every interaction. Our commitment to honesty and fairness is unwavering,
        fostering strong relationships with our clients, partners, and
        employees.
      </p>
    </div>,
    <div className='text-white flex flex-col gap-6'>
      <p className='text-2xl mt-2 md:text-3xl text-red  font-bold'>
        Pioneering Solutions
      </p>
      <p className='text-lg sm:text-xl'>
        {' '}
        Innovation is at the heart of what we do. We encourage creative thinking
        and embrace emerging technologies to continuously improve our services.
        By staying at the forefront of industry advancements, we provide our
        clients with pioneering solutions that drive growth and set new industry
        standards.
      </p>
    </div>,
  ];

  return (
    <div className='bg-blue-600 pt-16 pb-24'>
      <p className='font-bold text-4xl text-center pb-12 text-white'>
        Our Values
      </p>
      <Carousel slides={slides} />
    </div>
  );
};

export default ValuesCarousel;
