import React, { useState } from 'react';
import { busmanufacturing } from '../../../assets/images';
import { minusCircle, plusCircle } from '../../../assets/icons';
import { Link } from 'react-router-dom';

const BusinessManufacturing = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div className='flex flex-col items-center justify-center gap-16 sm:px-12 xl:px-52 px-3 py-8'>
      <div className='grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4 lg:gap-16'>
        <div className='md:block hidden'>
          <img src={busmanufacturing} alt='' />
        </div>
        <div className='flex flex-col gap-6'>
          <p className='font-semibold text-3xl md:text-4xl text-center md:text-left text-yellow'>
            Manufacturing
          </p>
          <div className='md:hidden block'>
            <img src={busmanufacturing} alt='' />
          </div>
          <p className='text-lg md:text-2xl font-medium'>
            Manufacturing your product is not something we take lightly.{' '}
          </p>
          <p className='text-lg'>
            When you partner with Tatabe as your product manufacturing solutions
            provider, we understand that you’re putting a lot of trust in us.
            Trust to solve your most complex manufacturing and supply chain
            challenges. Trust to do what we say we’ll do, with zero defects.
            Trust to understand just how important your product will be in the
            lives of those who will use it. That’s why we’ve built our process
            around providing you the most innovative and dependable
            manufacturing solutions available.
          </p>
          <button className='p-2 bg-yellow hover:bg-[#0f1725] transition-all duration-300 text-white rounded-sm w-full md:w-1/3'>
            Read Case Study
          </button>
        </div>
      </div>

      <div
        className={`flex flex-col gap-12 transition-all duration-1000  ${
          showContent ? 'flex' : 'hidden'
        }`}
      >
        <div className='flex flex-col md:flex-row gap-12 mt-16 px-3'>
          <div className='flex flex-col gap-6 w-full md:w-2/3'>
            <p className='font-semibold text-2xl md:text-3xl text-center md:text-left text-yellow'>
              Advanced global resources to bring highly complex products to
              life.
            </p>
            <p className='text-lg md:text-xl font-medium'>
              Choosing Tatabe to manufacture your product means you’re getting a
              true partner. We’ll take the time to understand your goals and
              work as an extension of your own team to find the smartest
              solution for your product. You’ll see an approach to manufacturing
              that focuses on innovation, continuous improvement and the
              flexibility to tailor our services to your exact needs — even if
              that means building out our capabilities to meet them. And you’ll
              benefit from a process that’s integrated with engineering, supply
              chain and aftermarket services to ensure the longevity of your
              product.
            </p>

            <Link
              to=''
              className='bg-yellow hover:bg-blue text-white rounded-sm p-2 w-full md:w-1/5'
            >
              Contact us
            </Link>
          </div>

          <div className='w-full md:w-1/3 text-lg flex-col gap-2'>
            <p className='text-xl md:text-3xl font-semibold mb-4 text-yellow'>
              Manufacturing Capabilities
            </p>
            <li>Solar Panels</li>
            <li>Leather shoes and boots</li>
            <li>Plastics</li>
            <li>Tomato Processing</li>
            <li>Recycling</li>
          </div>
        </div>

        <div className='bg-gray-200 text-white p-2 md:p-6'>
          <div className='flex md:flex-row flex-col md:gap-6 gap-4  w-full border-2 border-yellow md:pr-6'>
            <div className='text-xl bg-yellow flex items-center justify-center md:w-[10%] md:rotate-180 md:p-0 p-12'>
              <p className='md:rotate-90 md:tracking-wide'>MANUFACTURING</p>
            </div>

            <div className='flex flex-col font-light gap-6 w-full sm:w-[45%] py-16'>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl text-yellow'>
                  Experts In Plastic Manufacturing.
                </p>
                <p className='text-lg flex flex-col gap-2'>
                  At Tatabe Global Services Ltd, we stand as experts in the
                  intricate art and science of plastic manufacturing. With a
                  legacy of precision and a commitment to innovation, we
                  redefine industry standards, crafting an extensive array of
                  plastic products that fuel progress in various sectors across
                  the globe. <br></br>
                  <span className='text-yellow font-semibold'>
                    Cutting-Edge Technology:{' '}
                  </span>
                  Our manufacturing facilities are equipped with cutting-edge
                  technology, incorporating the latest in injection molding,
                  extrusion, and thermoforming processes. This technological
                  prowess allows us to produce plastic components with
                  unparalleled precision, consistency, and efficiency. From
                  intricate designs to large-scale productions, we transform raw
                  plastic materials into products that meet the most demanding
                  specifications.
                  <br></br>
                  <span className='text-yellow font-semibold'>
                    Customization and Versatility:{' '}
                  </span>
                  Understanding the diverse needs of our clients, we specialize
                  in customization. Whether it's bespoke components for
                  automotive industries, intricate parts for electronics, or
                  packaging solutions for consumer goods, we tailor our
                  manufacturing processes to create products that match exact
                  specifications. Our versatility enables us to adapt to the
                  unique requirements of every sector we serve. <br></br>
                  <span className='text-yellow font-semibold'>
                    Innovation in Material Science:{' '}
                  </span>
                  We are not just manufacturers; we are innovators. Our research
                  and development teams are dedicated to exploring new materials
                  and processes, pushing the boundaries of what plastics can
                  achieve. By staying at the forefront of material science, we
                  develop sustainable, lightweight, and high-performance plastic
                  solutions that drive advancements in various industries, from
                  aerospace to healthcare. <br></br>
                  <span className='text-yellow font-semibold'>
                    Quality Assurance:{' '}
                  </span>
                  Quality is the cornerstone of our manufacturing philosophy.
                  Rigorous quality control measures and adherence to
                  international standards ensure that every product leaving our
                  facility is of the highest quality. Our commitment to quality
                  assurance guarantees the reliability and durability of our
                  plastic components, instilling confidence in our clients and
                  end-users alike. <br></br>
                  <span className='text-yellow font-semibold'>
                    Environmental Responsibility:{' '}
                  </span>
                  In our pursuit of excellence, we are deeply committed to
                  environmental stewardship. We prioritize eco-friendly
                  materials and sustainable manufacturing practices, minimizing
                  waste and reducing our carbon footprint. By choosing our
                  products, you not only gain top-notch plastic components but
                  also contribute to a greener, more sustainable future.{' '}
                  <br></br>
                  <span className='text-yellow font-semibold'>
                    Partner with the Experts:{' '}
                  </span>
                  Choosing Tatabe Global Services Ltd means choosing expertise,
                  innovation, and reliability. Whether you need intricate
                  technical components or versatile everyday products, partner
                  with us to experience the precision and dedication that make
                  us leaders in plastic manufacturing. Together, let's shape a
                  future where quality and innovation drive progress in every
                  industry.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl text-yellow '>
                  Experts In Tomato Processing.
                </p>
                <p className='text-lg flex flex-col gap-2'>
                  At Tatabe Global Services Ltd, we are industry pioneers in
                  tomato processing, leveraging advanced techniques and
                  unmatched expertise to deliver high-quality tomato-based
                  products to the world. With a deep-rooted commitment to
                  excellence, we transform fresh, sun-ripened tomatoes into an
                  array of flavorful and nutritious offerings that elevate
                  culinary experiences globally. <br></br>
                  <span className='text-yellow font-semibold'>
                    Premium Quality, Every Time:{' '}
                  </span>
                  Our state-of-the-art processing facilities are meticulously
                  designed to maintain the freshness and nutritional integrity
                  of the tomatoes. From vine to can, our experts oversee every
                  step of the processing journey, ensuring that our products
                  retain the natural essence and vibrant taste of ripe tomatoes.
                  Quality is not just a standard; it's a promise we deliver with
                  every batch. <br></br>
                  <span className='text-yellow font-semibold'>
                    Innovative Tomato Solutions:{' '}
                  </span>{' '}
                  At Tatabe Global Services Ltd, we go beyond traditional
                  processing. Our team of skilled food technologists and chefs
                  continuously innovate, creating a diverse range of
                  tomato-based products. From rich pasta sauces and zesty salsas
                  to wholesome soups and tangy ketchups, our offerings cater to
                  a variety of tastes and culinary applications. Each product is
                  crafted with precision and passion, reflecting our dedication
                  to culinary excellence.
                  <br></br>
                  <span className='text-yellow font-semibold'>
                    Farm to Table Transparency:{' '}
                  </span>
                  We take pride in our farm-to-table approach. Partnering with
                  local farmers and sustainable agriculture initiatives, we
                  source the finest tomatoes at the peak of their flavor. This
                  commitment not only supports local communities but also
                  ensures that our customers receive products made from the
                  freshest and most responsibly grown produce available.{' '}
                  <br></br>
                  <span className='text-yellow font-semibold'>
                    Customized Solutions for Businesses:{' '}
                  </span>
                  Whether you're a restaurant owner, food retailer, or food
                  manufacturer, our tomato processing expertise extends to
                  creating custom solutions tailored to your unique
                  requirements. We collaborate closely with businesses to
                  develop bespoke tomato products that align with their brand
                  identity and culinary vision, delivering taste, quality, and
                  consistency every time.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl text-yellow'>
                  Experts In Leather Shoes & Boots.
                </p>
                <p className='text-lg flex flex-col gap-2'>
                  <span className='font-semibold text-yellow'>
                    Craftsmanship:{' '}
                  </span>{' '}
                  Our shoe manufacturing division is dedicated to the art of
                  shoemaking. Our skilled artisans craft footwear with attention
                  to detail, ensuring comfort, style, and durability. <br></br>
                  <span className='font-semibold text-yellow'>
                    Range of Styles:{' '}
                  </span>{' '}
                  Whether you're looking for formal shoes, casual footwear, or
                  specialized industrial boots, Tatabe Global Services Ltd
                  offers a diverse range of styles to meet your needs. <br></br>
                  <span className='font-semibold text-yellow'>
                    Bespoke Solutions:{' '}
                  </span>{' '}
                  We also provide bespoke shoemaking services, allowing you to
                  create customized footwear tailored to your unique
                  preferences.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl text-yellow'>
                  Experts In Solar Panel Manufacturing.
                </p>
                <p className='text-lg flex flex-col gap-2'>
                  <span className='font-semibold text-yellow'>
                    Cutting-Edge Technology:{' '}
                  </span>{' '}
                  <br></br>Our state-of-the-art facilities are equipped with
                  cutting-edge technology, ensuring precision and efficiency in
                  every solar panel we produce. We utilize the latest
                  advancements to create high-performance, durable, and
                  eco-friendly solar solutions.
                  <br></br>
                  <span className='font-semibold text-yellow'>
                    Expert Craftsmanship:{' '}
                  </span>
                  Our solar panel manufacturing division thrives on
                  craftsmanship. Our skilled engineers and technicians
                  meticulously design and assemble solar panels, guaranteeing
                  top-notch quality and reliability.
                  <br></br>
                  <span className='font-semibold text-yellow'>
                    Customized Solutions:{' '}
                  </span>{' '}
                  We understand that every solar project is unique. That's why
                  we offer customized solar panel solutions tailored to your
                  specific requirements. Whether you need panels for
                  residential, commercial, or industrial applications, we
                  provide bespoke solutions to optimize energy efficiency and
                  meet your sustainability goals. <br></br>
                  <span className='font-semibold text-yellow'>
                    Environmentally Conscious:{' '}
                  </span>
                  We prioritize environmental responsibility. Our solar panels
                  are manufactured using eco-friendly materials and processes,
                  minimizing our carbon footprint. By choosing our solar
                  solutions, you contribute to a cleaner planet and a brighter
                  future for generations to come.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl text-yellow'>Experts In Recycling.</p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
            </div>

            <div className='w-full md:w-[45%] flex flex-col gap-6 text-lg pt-6 pb-16 md:py-16'>
              <p className='font-semibold text-2xl text-yellow'>
                Enhancing your brand reputation and time to market.
              </p>
              <p>
                The Tatabe supply chain team applies four key processes to every
                engagement to ensure your supply chain is protected and your
                time-to-market needs are met.
              </p>
              <li>
                ENGINEERING INTEGRATION.<br></br> Collaboration with our
                engineering teams at the front end to identify the best
                strategic partners for your product and provide a customized
                solution for your needs.
              </li>
              <li>
                ESTABLISHING SHARED GOALS.<br></br> Working together to design
                solutions that are unique to you and your end customer.
              </li>
              <li>
                PROACTIVE MITIGATION.<br></br> Providing ongoing supply chain
                continuity through the use of proprietary risk mitigation tools.
              </li>
              <li>
                FULL LIFECYCLE SUPPORT.<br></br> Dedicated resources ready to
                support you from the initial concept to servicing the product in
                the market.
              </li>
              <p>
                Our flexible process allows us to identify, quantify, prioritize
                and mitigate any risks to your supply chain, so you can get your
                product into your customers’ hands in the most efficient and
                reliable way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='flex flex-col justify-center items-center gap-2 cursor-pointer '
        onClick={() => {
          setShowContent(!showContent);
        }}
      >
        <img src={showContent ? minusCircle : plusCircle} alt='' />
        <p className='text-yellow hover:text-blue'>{showContent ? 'COLLAPSE' : 'EXPAND'}</p>
      </div>
    </div>
  );
};

export default BusinessManufacturing;
