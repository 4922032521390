import { Routes, Route } from 'react-router-dom';
import {
  ABOUT,
  BLOG,
  BUSINESSES,
  CAREER,
  CONTACT,
  DISTRIBUTION_MGT,
  HOME,
  IMPORTEXPORT,
  LEADERSHIP,
  LOCATIONS,
  LOGISTICS_SUPPLYCHAIN,
  MANUFACTURING,
  MEDIA,
  NEWS,
  OILANDGAS,
  PLASTIC,
  PRIVACY,
  SHIPPING,
  SUSTAINABILITY,
  SUSTAINING_SERVICES,
  TERMS,
  TOMATO,
  TRADE_DISTRIBUTION,
  WHY,
} from './CONSTANTS';
import {
  About,
  Blog,
  Businesses,
  Career,
  Contact,
  DistributionManagement,
  Home,
  ImportExport,
  Leadership,
  Locations,
  LogisticsSupplychain,
  Manufacturing,
  Media,
  News,
  OilAndGas,
  Plastic,
  Privacy,
  Shipping,
  Sustainability,
  SustainingServices,
  Terms,
  Tomato,
  TradeAndDistribution,
  Why,
} from '../pages';

const RouterConfig = () => {
  return (
    <div>
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={ABOUT} element={<About />} />
        <Route path={BUSINESSES} element={<Businesses />} />
        <Route path={CAREER} element={<Career />} />
        <Route path={CONTACT} element={<Contact />} />
        <Route path={MEDIA} element={<Media />} />
        <Route path={SUSTAINABILITY} element={<Sustainability />} />
        <Route path={IMPORTEXPORT} element={<ImportExport />} />
        <Route path={LEADERSHIP} element={<Leadership />} />
        <Route path={LOCATIONS} element={<Locations />} />
        <Route path={NEWS} element={<News />} />
        <Route path={OILANDGAS} element={<OilAndGas />} />
        <Route path={PLASTIC} element={<Plastic />} />
        <Route path={SHIPPING} element={<Shipping />} />
        <Route path={TOMATO} element={<Tomato />} />
        <Route path={WHY} element={<Why />} />
        <Route path={TERMS} element={<Terms />} />
        <Route path={PRIVACY} element={<Privacy />} />
        <Route path={BLOG} element={<Blog />} />
        <Route path={MANUFACTURING} element={<Manufacturing />} />
        <Route path={DISTRIBUTION_MGT} element={<DistributionManagement />} />
        <Route path={SUSTAINING_SERVICES} element={<SustainingServices />} />
        <Route path={TRADE_DISTRIBUTION} element={<TradeAndDistribution />} />
        <Route
          path={LOGISTICS_SUPPLYCHAIN}
          element={<LogisticsSupplychain />}
        />
      </Routes>
    </div>
  );
};

export default RouterConfig;
