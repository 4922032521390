import React from 'react';
import { Link } from 'react-router-dom';
import { trucking, vessel } from '../../../assets/images';

const OilAndGasCard = () => {
  const keyMarkets = [
    {
      id: 1,
      image: trucking,
      name: 'Oil And Gas Sales',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
    {
      id: 2,
      image: vessel,
      name: 'Oil and Gas Vessel Chattering',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
  ];
  return (
    <div className='px-3 sm:px-12 lg:px-96 text-gray py-16'>
      <p className='text-3xl md:text-4xl font-bold text-center text-blue-600 mb-12'>
        FOCUSED ON <span className='text-red'>KEY INDUSTRIAL MARKETS</span>.
      </p>
      <div className='grid md:grid-cols-2 grid-cols-1 gap-8 xl:gap-16 '>
        {keyMarkets.map(({ id, image, name, text, link }) => (
          <div
            className='flex flex-col gap-4 sm:gap-2 xl:gap-4 cursor-pointer overflow-hidden pb-2  bg-white rounded-md shadow-md'
            key={id}
          >
            <img
              src={image}
              alt=''
              className='rounded-t-md md:h-40 h-56 transform transition-transform duration-300 hover:scale-110'
            />
            <p className='text-xl font-semibold text-center px-2 text-blue-600'>
              {name}
            </p>
            <p className='h-28 mb-6 px-2 text-blue-600 text-center'>{text}</p>
            <Link
              to={link}
              className='p-2 bg-red text-white hover:bg-blue transition-all duration-300 w-[93%] rounded-sm mt-6 text-center mx-auto'
            >
              Read more
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OilAndGasCard;
