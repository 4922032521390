import React from 'react';
import { scs } from '../../../assets/images';

const CircularEconomy = () => {
  return (
    <div className='px-3 sm:px-12 xl:px-56 py-16 '>
      <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div className='flex flex-col gap-6'>
          <p className='text-3xl md:text-4xl font-semibold text-center md:text-left'>
            Supporting the circular economy
          </p>
          <div className='block md:hidden'>
            <img src={scs} alt='' />
          </div>
          <p className='text-lg text-center md:text-left'>
            Tatabe is committed to environmental sustainability across our full
            value stream of solutions and we consider it an integral aspect of
            our “zero defect” culture. Integrated design and development, supply
            chain solutions, new product introduction, manufacturing and
            sustaining services positions Tatabe to drive innovation, value and
            sustainability throughout the circular economy.
          </p>
          <p className='text-lg text-center md:text-left'>
            <span className='hidden md:block'>
              Tatabe’s design and development teams create innovative product
              and product packaging engineering solutions in collaboration with{' '}
            </span>
          </p>
        </div>
        <div className='md:block hidden'>
          <img src={scs} alt='' />
        </div>
      </div>

      <div>
        <p className='text-lg text-center md:text-left'>
          <span className='md:hidden block'>
            Tatabe’s design and development teams create innovative product and
            product packaging engineering solutions in collaboration with{' '}
          </span>
          our customers to design products with environmental impacts and goals
          understood in advance. Our supply chain solutions teams ensure
          supplier accountability and compliance as well as optimized material
          usage and eco-friendly material and packaging selection. Through
          Tatabe’ expertise in new product introduction and manufacturing, we
          efficiently bring products to market while focusing on reducing the
          environmental impact of our manufacturing processes. Lastly, Tatabe’s
          unique Aftermarket Services solutions extend product lifecycles,
          enable recycling and re-usability and ensure appropriate end of life
          disposal.
        </p>
      </div>
    </div>
  );
};

export default CircularEconomy;
