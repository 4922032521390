import React from 'react';
import BusinessSupplyChain from './BusinessSupplyChain';
import BusinessOilAndGas from './BusinessOilAndGas';
import BusinessManufacturing from './BusinessManufacturing';
import BusinessSolar from './BusinessSolar';
import SustainingServices from './SustainingServices';

const BusinessCategory = () => {
  return (
    <div className='py-16'>
      <BusinessSupplyChain />
      <BusinessOilAndGas />
      <BusinessSolar />
      <BusinessManufacturing />
      <SustainingServices />
    </div>
  );
};

export default BusinessCategory;
