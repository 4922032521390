import React from 'react';
import { Link } from 'react-router-dom';

const ResponsibleBusiness = () => {
  return (
    <div className='px-5 sm:px-12 xl:px-56 xm:pr-12 xl:pr-20 py-16'>
      <div className='flex flex-col gap-6'>
        <h3 className='font-semibold text-3xl md:text-5xl text-center'>
          Doing business the responsible way.
        </h3>
        <p className='text-lg text-center md:text-left'>
          We're driven by fairness, honesty and ethical business. With zero
          tolerance for corruption and dishonest business practices, we’re
          accountable for getting things done the right way. Excellence only
          flourishes when we hold ourselves to higher standards in every
          interaction with customers, suppliers and our team members. Our Values
          and Leadership Behaviors support our culture of accountability by
          defining how we will conduct ourselves. As a proud member of the
          Responsible Business Alliance, we are part of a global coalition
          dedicated to improving the working conditions throughout our global
          supply chain through initiatives focused on the well-being, promotion
          and rights of workers.
        </p>
        <p className='text-lg text-center md:text-left'>
          We regularly dedicate time and resources to ensure that our company is
          aligned with anti-corruption laws in all jurisdictions. We assess
          compliance and social risks associated with each individual role, and
          we continue to educate employees at all levels of the organization
          about the importance of avoiding unfair business practices, complying
          with fair competition laws and other critical regulatory, social and
          ethics topics. Training completion is tracked in the company’s
          Learning Management System (LMS).
        </p>
      </div>
      <div className='text-white text-lg md:text-2xl font-medium grid grid-cols-1 md:grid-cols-4 mt-16 gap-2 md:gap-0'>
        <Link to="" className='h-32 md:h-36 shadow-md p-4 md:p-8 rounded-sm flex items-center justify-center bg-[#BC772B] hover:bg-[#d29554] md:col-start-1 md:col-span-4'>
          Social Responsibility Policy
        </Link>
        <Link to="" className='h-32 md:h-36 shadow-md p-4 md:p-8 rounded-sm flex items-center justify-center bg-[#5B1928] hover:bg-[#7c3747] md:col-start-1 md:col-span-2'>
          Tatabe Code of Conduct
        </Link>
        <Link to="" className='h-32 md:h-36 shadow-md p-4 md:p-8 rounded-sm flex items-center justify-center bg-[#77B39E] hover:bg-[#89c7b1] md:col-start-3 md:col-span-4'>
          Nigeria Transparency In Supply Chains Act
        </Link>
        <Link to="" className='h-32 md:h-36 shadow-md p-4 md:p-8 rounded-sm flex items-center justify-center bg-[#A0B350] hover:bg-[#b1c65e] md:col-start-1 md:col-span-2'>
          Supplier Code of Conduct
        </Link>
        <Link to="" className='h-32 md:h-36 shadow-md p-4 md:p-8 rounded-sm flex items-center justify-center bg-[#25373D] hover:bg-[#2d4249] md:col-start-3 md:col-span-4'>
          UK Modern Slavery Act
        </Link>
      </div>

      {/* <div className='text-white text-lg md:text-2xl font-medium grid grid-cols-1 md:grid-cols-4 mt-16 gap-2'>
        <div className='relative group'>
          <div className='py-14 px-8 md:px-2 rounded-sm flex items-center justify-center bg-[#BC772B] md:col-start-1 md:col-span-4'>
            Social Responsibility Policy
          </div>
          <div className='absolute inset-x-0 bottom-0 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300'>
            <div className='bg-red py-14 px-8'>
              <p>Additional content here.</p>
              <button className='bg-white text-red px-4 py-2 rounded-full hover:bg-red hover:text-white transition-colors'>
                Click Me
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ResponsibleBusiness;
