import React from 'react';
import ContactView from './ContactView';
import { Wrapper } from '../../components/modules/wrapper';

export const ContactContainer = () => {
  return (
    <Wrapper>
      <ContactView />
    </Wrapper>
  );
};
