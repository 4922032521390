import React from 'react';
import { bajaj, dangote, zim, hapag, joatelim } from '../../../assets/images';
import { ceva, cma, mrs } from '../../../assets/icons';

const Partners = () => {
  return (
    <div className='py-16 text-[#162133] bg-gray-400'>
    <p className='font-semibold text-2xl  md:text-5xl text-center text-blue-600'>
      OUR CLIENTS <span className='text-red'>AROUND THE WORLD</span>
    </p>
    <p className='text-center font-normal md:font-medium text-base md:text-xl mt-4 mb-8 md:my-8'>Some of the few of many clients that we have worked with</p>
    <div className='flex items-center justify-center gap-2 md:gap-8 md:px-20 sm:px-12 px-1'>
      <img src={dangote} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
      <img src={cma} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
      <img src={bajaj} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
      <img src={hapag} alt='' className=' h-4 mx-auto' />
      <img src={zim} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
      <img src={mrs} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
      <img src={joatelim} alt='' className='h-8 md:h-20  mx-auto' />
      <img src={ceva} alt='' className=' h-4 w-4 md:h-16 md:w-16 mx-auto' />
    </div>
  </div>
  );
};

export default Partners;
