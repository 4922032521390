import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../../../utils';

const BlogCards = () => {
  const cardsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = blogPosts.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className='w-full lg:w-2/3 py-16 px-3 sm:px-12 lg:px-36 '>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
        {currentCards.map(({ id, title, description, link }) => (
          <div
            className='rounded-lg shadow-md p-4 md:p-8 flex flex-col gap-4 items-center justify-center border-gray-400 border-[0.5px] hover:translate-y-2 transition-all duration-300'
            key={id}
          >
            <h2 className='text-blue font-semibold text-xl text-center'>
              {title}
            </h2>
            <p className='text-base text-blue text-center'>{description}</p>
            <Link
              to={link}
              className='bg-red-100 text-white hover:bg-blue transition-all duration-300 mt-2 p-2 rounded-sm'
            >
              Read more
            </Link>
          </div>
        ))}
      </div>
      <div className='mt-16 text-center'>
        {Array.from(
          { length: Math.ceil(blogPosts.length / cardsPerPage) },
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`mr-2 px-4 py-2 rounded ${
                currentPage === index + 1
                  ? 'bg-blue text-white'
                  : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default BlogCards;
