import React from 'react';
import BlogView from './BlogView';
import { Wrapper } from '../../components/modules/wrapper';

export const BlogContainer = () => {
  return (
    <Wrapper>
      <BlogView />
    </Wrapper>
  );
};
