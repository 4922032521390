import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function Input({ nameAndId, placeholder, label, type }) {
  return (
    <div className='flex flex-col gap-2 mb-2'>
      <label className='' htmlFor={nameAndId}>
        <span className='text-base text-black'>{label}</span>
      </label>
      <div className='flex items-center'>
        <Field
          name={nameAndId}
          id={nameAndId}
          as={'input'}
          placeholder={placeholder}
          type={type || 'text'}
          className='bg-white border-blue-300 border-[0.5px] w-full rounded-sm placeholder-gray-100 text-black p-2'
        />
      </div>
      <ErrorMessage
        component='label'
        name={nameAndId}
        className=' w-full text-red'
      />
    </div>
  );
}
Input.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconUrl: PropTypes.string,
};

export default Input;
