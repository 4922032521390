import React from 'react';
import Wrapper from '../../components/modules/wrapper/Wrapper';
import NewsView from './NewsView';

export const NewsContainer = () => {
  return (
    <Wrapper>
      <NewsView />
    </Wrapper>
  );
};
