import React from 'react';
import {
  flatbedwhite,
  manufacturingwhite,
  oilwhite,
  recyclewhite,
  supplychainswhite,
} from '../../../assets/icons';

const BusinessBand = () => {
  const ikon = [
    { id: 1, icon: supplychainswhite, text: 'LOGISTICS & SUPPLY CHAIN' },
    { id: 2, icon: oilwhite, text: 'OIL AND GAS' },
    { id: 3, icon: manufacturingwhite, text: 'MANUFACTURING' },
    { id: 4, icon: recyclewhite, text: 'SUSTAINING SERVICES' },
    { id: 5, icon: flatbedwhite, text: 'TRADE AND DISTRIBUTION' },
  ];
  return (
    <div className='bg-gray-100 md:py-5 md:px-12 p-3 grid md:grid-cols-5 grid-cols-2 items-center justify-center gap-2 md:gap-4'>
      {ikon.map(({ id, icon, text }) => (
        <div key={id} className='hover:text-[#0f1725] md:h-full h-40'>
          <div className='flex md:flex-row flex-col items-center justify-between md:justify-center gap-4 text-center'>
            <div className='h-20 w-20 rounded-full border-2 border-white hover:border-[#0f1725] transition-all duration-700 flex items-center justify-center p-2'>
              <img src={icon} alt='' />
            </div>
            <p className='md:w-48 text-white'>{text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BusinessBand;
