import React from 'react';

const Excited = () => {
  return (
    <div className=' flex flex-col gap-6 py-16 px-3 sm:px-12 lg:px-56 items-center justify-center'>
      <div className='text-3xl md:text-4xl font-semibold text-center sm:px-8 lg:px-28'>
        <p className='text-blue-600'>
          DISCOVER <span className='text-red'>TATABE</span>
        </p>
        <p className=' mt-6 text-blue-600'>
          Your trusted business partner in Nigeria.
        </p>
      </div>
      <p className='text-lg text-center text-gray-100'>
        Welcome to <span className='text-red'>Tatabe Global Services Ltd</span>,
        your all-in-one business solution in Nigeria. We specialize in{' '}
        <span className='text-blue-600'>
          manufacturing, logistics, supply chain, oil and gas, sustaining
          services, and trade and distribution.
        </span>{' '}
        From plastic and tomato processing to shoe manufacturing and solar
        energy, we offer diverse manufacturing capabilities. Our logistics
        expertise includes haulage, container operations, import/export,
        barging, and vessel chartering. Committed to sustainability, we lead in
        plastic recycling and diesel distribution. Partner with us for
        streamlined solutions tailored to your needs.
      </p>
      <button className='bg-red p-2 rounded-sm text-white hover:bg-blue'>Find out more about us</button>
    </div>
  );
};

export default Excited;
