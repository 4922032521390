import React from 'react';
import MediaView from './MediaView';
import { Wrapper } from '../../components/modules/wrapper';

export const MediaContainer = () => {
  return (
    <Wrapper>
      <MediaView />
    </Wrapper>
  );
};
