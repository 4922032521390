import React from 'react';

const BlogTags = () => {
  return (
    <div className='w-full lg:w-1/3 flex flex-col gap-28 py-16 px-3'>
      <div>
        <p className='font-semibold text-red'>SEARCH</p>
        <input
          type='search'
          placeholder='search...'
          className='p-3 rounded-sm border-gray-400 border-[0.5px] mt-8 placeholder:text-gray-300'
        />
      </div>

      <div>
        <p className='font-semibold text-red'>CATEGORIES</p>
        <div className='flex flex-col gap-4 mt-8 text-lg text-blue hover:text-red '>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Technology</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Logistics</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Container</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Manufacturing</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Plastic</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Recycling</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Supply Chains</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Solar</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Finance</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Sustainability</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Barging</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Haulage</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Import</div>
          <div className='text-blue hover:text-red cursor-pointer border-b-gray-400 pb-4 border-b-[0.5px] mr-6 lg:mr-48'>Export</div>
          <div className='text-blue hover:text-red cursor-pointer'>Vessel Chattering</div>
        </div>
      </div>
    </div>
  );
};

export default BlogTags;
