import React from 'react';
import { manufacturing } from '../../../assets/images';
import {
  chevrondown,
  globe,
  handholdinghand,
  peoplegroup,
} from '../../../assets/icons';

const Pillars = () => {
  const pillar = [
    {
      id: 1,
      icon: handholdinghand,
      image: '',
      backg: 'bg-gradient-to-t from-[#BC772B] via-[30%] via-[#BC772B]',
      title: 'Innovator',
      text: "We innovate for sustainability to proactively solve today's challenges while focusing on tomorrow's opportunities",
    },
    {
      id: 2,
      icon: peoplegroup,
      image: '',
      backg: 'bg-gradient-to-t from-[#5B1928] via-[30%] via-[#5B1928]',
      title: 'Responsible Employer',
      text: 'Advocating for diversity, encouraging employee development and ensuring ethical labor practices',
    },
    {
      id: 3,
      icon: handholdinghand,
      image: '',
      backg: 'bg-gradient-to-t from-[#77B39E] via-[30%] via-[#77B39E]',
      title: 'Community Partner',
      text: 'Making a meaningful impact to our communities through volunteerism and charitable giving',
    },
    {
      id: 4,
      icon: globe,
      image: '',
      backg: 'bg-gradient-to-t from-[#A0B350] via-[30%] via-[#A0B350]',
      title: 'Global Citizen',
      text: 'Commited to evironmental sustainability through our operations, supplier programs and product design services',
    },
    {
      id: 5,
      icon: handholdinghand,
      image: '',
      backg: ' bg-gradient-to-t from-[#25373D] via-[30%] via-[#25373D]',
      title: 'Corporate Governance',
      text: 'We foster a culture of accountability and competency and ethically manage our business for the long-term benefit of our stakeholders',
    },
  ];
  return (
    <div className='bg-gray-400'>
      <div className='px-3 sm:px-20 xl:px-64 py-16 md:py-24 flex flex-col gap-8 items-center justify-center'>
        <h3 className='text-3xl md:text-4xl font-semibold tracking-wide text-center'>
          Our five pillars of Environmental, Social and Corporate Governance
          (ESG).
        </h3>
        <p className='text-lg text-center'>
          Tatabe Global. has established an ESG program defined by five pillars
          that reinforce our vision to build a better world by being an
          innovator, a responsible employer, a community partner, a global
          citizen and a promoter of corporate governance.
        </p>
      </div>
      <div className='flex flex-wrap items-center justify-center'>
        {pillar.map(({ id, icon, title, text, backg }) => (
          <div
            key={id}
            className=' xl:w-1/5 lg:w-1/4 md:w-1/3 w-1/2'
            style={{
              backgroundImage: `url(${manufacturing})`,
              aspectRatio: '1 / 1',
            }}
          >
            <div
              className={`flex flex-col justify-between gap-2 h-full ${backg} p-2 md:p-6 text-white `}
            >
              <img
                src={icon}
                alt={title}
                className='h-6 md:h-16 w-6 md:w-16 mx-auto'
              />
              <p className='text-sm text-center lg:text-left lg:text-lg font-semibold'>
                {title}
              </p>
              <p className='text-xs text-center lg:text-left lg:text-base'>
                {text}
              </p>
              <img
                src={chevrondown}
                alt=''
                className='h-6 md:h-16 w-6 md:w-16 mx-auto mb-0'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pillars;
