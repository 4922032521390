import React from 'react';
import {
  Belt,
  BusinessBand,
  BusinessCategory,
  BusinessHero,
  Development,
} from '../../components/layout/Businesses';
import { Businesses, Map } from '../../components/layout/Home';
import ContactForm from '../../components/layout/Contact/ContactForm';

const BusinessesView = () => {
  return (
    <div>
      <BusinessHero />
      <Development />
      <BusinessBand />
      <BusinessCategory />
      <Belt />
      <Businesses />
      <Map />
      <ContactForm />
    </div>
  );
};

export default BusinessesView;
