/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { clients, products, projects, years } from '../../../assets/icons';

const Statistics = () => {
  const stat = [
    {
      id: 1,
      stat: '14+',
      text: 'Years of Experience',
      icon: years,
      col: 'yellow',
    },
    {
      id: 2,
      stat: '68+',
      text: 'Projects Per Year',
      icon: projects,
      col: 'blue-100',
    },
    {
      id: 3,
      stat: '23K+',
      text: 'Products Per Month',
      icon: products,
      col: 'red',
    },
    { id: 4, stat: '100+', text: 'Happy Clients', icon: clients, col: 'green-100' },
  ];
  return (
    <div className='px-3 xl:px-40 gap-16 md:gap-28 bg-blue-600 text-white py-16 grid grid-cols-1 md:grid-cols-2'>
      <div className='flex flex-col gap-6'>
        <p className='text-4xl md:text-6xl font-bold md:text-start text-center'>
          We offer our customers a{' '}
          <span className='text-red'>deeper understanding</span> of market
          needs.
        </p>
        <p className='text-lg md:text-start text-center'>
          We have over 15 years of experience in supply chains across the world
          with foundations built from our on-the-ground presence in Africa.
        </p>
        <p className='md:text-start text-center'>
          Our operating capabilities in global supply chains, manufacturing, trading,
          logistics, distribution, and oil and gas set us apart from our
          peers. While our strategic manufacturing assets in key destination
          markets strengthen our service to customers.{' '}
        </p>
      </div>
      <div className=''>
        <div className=' grid grid-cols-2 gap-4 xl:gap-8 items-center justify-center '>
          {stat.map(({ id, stat, text, icon, col }) => (
            <div
              key={id}
              className={`h-40 md:h-80 w-40 md:w-80 rounded-full border-2 border-${col} p-4 flex items-center justify-center flex-col`}
            >
              <img src={icon} alt='' className='h-8 md:h-16 w-8 md:w-16' />
              <p className={`font-semibold text-4xl md:text-7xl text-${col}`}>{stat}</p>
              <p className={`text-base md:text-2xl font-semibold text-${col} text-center`}>{text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
