import React from 'react';
import LocationsView from './LocationsView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const LocationsContainer = () => {
  return (
    <Wrapper>
      <LocationsView />
    </Wrapper>
  );
};
