import React from 'react';

const AboutValues = () => {
  return (
    <div className='flex md:flex-row flex-col px-5 sm:px-12 xl:px-24 py-16'>
      <div className=' p-2 lg:p-8 flex flex-col gap-2 text-center text-blue-600'>
        <p className='font-bold sm:text-4xl text-2xl '>OUR VISION</p>
        <p className='text-xl'>Shaping a Brighter Future</p>
        <p className='mt-4 text-base sm:text-lg text-black'>
          Our vision is to be a global leader in the Oil and Gas industry,
          Supply chain industry and the manufacturing industry, setting the
          standard for excellence and sustainability in every industry we touch.
          We aspire to shape a brighter future by continuously innovating,
          expanding our horizons, and fostering partnerships that drive
          progress. We envision a world where responsible business practices and
          client-centric solutions are at the forefront of industry norms.
        </p>
      </div>
      <div className="w-full md:w-2 h-[1px] md:h-48 bg-blue-600 md:my-auto mt-12"></div>
      <div className='text-blue-600 p-2 lg:p-8 flex flex-col gap-2 text-center md:mt-0 mt-12'>
        <p className='font-bold sm:text-4xl text-2xl '>OUR MISSION</p>
        <p className='text-xl'>Empowering Progress, Sustaining Excellence</p>
        <p className='mt-4 text-base sm:text-lg text-black'>
          At Tatabe Global Services Ltd, our mission is to empower progress by
          providing integrated services that drive growth and development across
          diverse industries. We are committed to delivering innovative
          solutions that not only meet but exceed the evolving needs of our
          clients. Our unwavering dedication to sustainability and responsible
          business practices underpins our every action, ensuring that we
          contribute positively to society and the environment.
        </p>
      </div>
    </div>
  );
};

export default AboutValues;
