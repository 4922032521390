import React from 'react';

const Highlights = () => {
  return (
    <div className='flex flex-col gap-6 px-3 sm:px-12 xl:px-56 py-16 lg:mr-20 text-center md:text-left text-lg'>
      <p className='font-semibold text-3xl md:text-4xl text-center lg:text-left'>Corporate Governance Highlights</p>
      <p>
        Tatabe recognizes the importance of strong leadership and a culture of
        accountability. Tatabe’ Board of Directors oversees executive management
        to competently and ethically manage Tatabe’ operations for the long-term
        benefit of shareholders. The Board has adopted governance documents to
        promote ethical behavior, effective decision making, and proper
        monitoring in the pursuit of performance and compliance.
      </p>
      <div className="text-center md:text-left">
        <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full md:w-1/4 text-sm md:text-base'>
          LEARN MORE
        </button>
      </div>
    </div>
  );
};

export default Highlights;
