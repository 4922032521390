import React from 'react';

const Privacy = () => {
  return (
    <div className='text-[#0f1725] text-lg py-16 px-3 sm:px-12 lg:px-56'>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        1. Introduction
      </h3>
      <p>
        <span className='text-red'>Tatabe Global Services Ltd.</span> ("Tatabe,"
        "we," "our," or "us") values your privacy and is committed to protecting
        your personal information. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your personal data when you visit our
        website, use our services, or interact with us. By accessing or using
        our services, you consent to the practices described in this Privacy
        Policy.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        2. Information We Collect{' '}
      </h3>
      <p>
        We may collect personal information such as your name, email address,
        phone number, address, company details, and payment information when you
        voluntarily provide it to us, such as when you fill out forms on our
        website or communicate with us via email or phone.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        3. How We Use Your Information
      </h3>
      <p>
        We may use the information we collect for various purposes, including:{' '}
      </p>
      <li>Providing and personalizing our services </li>
      <li>Processing transactions and payments </li>
      <li>
        Communicating with you, including responding to your inquiries and
        providing customer support{' '}
      </li>
      <li>
        Sending promotional emails or newsletters if you have opted in to
        receive them{' '}
      </li>
      <li>Analyzing website usage and improving our services </li>
      <li> Complying with legal and regulatory requirements</li>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        4. Information Sharing and Disclosure{' '}
      </h3>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties without your consent, except for trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, as long as those parties agree to keep this
        information confidential.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        5. Data Security
      </h3>
      <p>
        {' '}
        We implement appropriate security measures to protect your personal
        information from unauthorized access, disclosure, alteration, and
        destruction. However, no data transmission or storage system can be
        guaranteed to be 100% secure. Therefore, while we strive to use
        commercially acceptable means to protect your personal information, we
        cannot guarantee its absolute security.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        6. Cookies and Other Technologies{' '}
      </h3>
      <p>
        We may use cookies and similar technologies to enhance your user
        experience. You can control cookies through your browser settings;
        however, disabling cookies may limit certain features of our website.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        7. Links to Third-Party Websites
      </h3>
      <p>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or the content of these websites.
        We encourage you to read the privacy policies of linked sites as they
        may collect personal information from you.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        8. Children's Privacy{' '}
      </h3>
      <p>
        Our services are not directed to individuals under the age of 18. We do
        not knowingly collect personal information from children. If we learn
        that we have collected the personal information of a child under 18, we
        will take steps to delete the information as soon as possible.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        9. Changes to this Privacy Policy{' '}
      </h3>
      <p>
        {' '}
        We reserve the right to modify this Privacy Policy at any time. Changes
        and clarifications will take effect immediately upon their posting on
        the website. We encourage you to review this Privacy Policy periodically
        for any changes.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        10. Contact Us{' '}
      </h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at tatabeglobalservicesltd@gmail.com.
      </p>
      <p>{new Date().getDate()}</p>
    </div>
  );
};

export default Privacy;
