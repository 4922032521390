import React, { useState, useEffect } from 'react';
import { next, prev } from '../../../assets/icons';
import { portrait } from '../../../assets/images';

const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div className='relative'>
      <div className='overflow-hidden'>
        <div
          className='flex transition-transform duration-1000 ease-in-out transform'
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className='w-full flex-shrink-0 px-5 sm:px-12 xl:px-64 text-center'
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
      <div className=' absolute left-1/2 transform -translate-x-1/2 flex gap-4 mt-6'>
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`w-4 h-4 rounded-full ${
              index === currentSlide ? 'bg-red' : 'bg-white'
            }`}
          ></button>
        ))}
      </div>
      <button
        onClick={goToPrevSlide}
        className='absolute top-1/2 left-24 transform -translate-y-1/2 hidden md:block'
      >
        <img src={prev} alt='previous' />
      </button>
      <button
        onClick={goToNextSlide}
        className='absolute top-1/2 right-24 transform -translate-y-1/2 hidden md:block'
      >
        <img src={next} alt='next' />
      </button>
    </div>
  );
};

const Testimonials = () => {
  const slides = [
    <div className='bg-white rounded-md p-4 shadow-md border-[0.05px] border-gray-400'>
      <div className=''>
        <img src={portrait} alt='' className='rounded-full h-16 w-16' />
      </div>
      <p className='text-blue-600 mt-2 text-base md:text-lg'>
        We are building Tatabe Global to be the one stop organization to cater
        to all our clients' needs from procurement and supply chain services to
        oil and gas to cement distribution, to plastic recycling and
        manufacturing as well as manufacturing of leather products.
      </p>
      <p className='text-right text-red text-sm mt-4'>- Ali Tatabe (CEO)</p>
    </div>,
    <div className='bg-white rounded-md p-4 shadow-md border-[0.05px] border-gray-400'>
      <div className=''>
        <img src={portrait} alt='' className='rounded-full h-16 w-16' />
      </div>
      <p className='text-blue-600 text-base md:text-lg mt-2'>
        Sustainability is not just a buzzword; it's a fundamental commitment. We
        are dedicated to environmental sustainability and responsible business
        practices. Through eco-friendly initiatives, resource optimization, and
        community engagement, we aim to leave a positive legacy for future
        generations.
      </p>
      <p className='text-right text-red text-sm mt-4'>- Ali Tatabe (CEO)</p>
    </div>,
    <div className='bg-white rounded-md p-4 shadow-md border-[0.05px] border-gray-400'>
      <div className=''>
        <img src={portrait} alt='' className='rounded-full h-16 w-16' />
      </div>
      <p className='text-blue-600 text-base md:text-lg mt-2'>
        Our clients are the driving force behind everything we do. We place
        their needs at the center of our operations and go the extra mile to
        exceed their expectations. Your success is our mission, and we are
        committed to delivering value, reliability, and exceptional service in
        every partnership
      </p>
      <p className='text-right text-red text-sm mt-4'>- Andrew Ogbodo (COO)</p>
    </div>,
  ];

  return (
    <div className='bg-blue-600 pt-16 pb-24'>
      <h2 className='font-semibold text-2xl md:text-5xl text-white text-center pb-10'>
        TESTIMONIALS <span className='text-red'>FROM OUR CLIENTS</span>
      </h2>
      <Carousel slides={slides} />
    </div>
  );
};

export default Testimonials;
