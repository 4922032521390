import React from 'react';
import LogisticsSupplychainView from './LogisticsSupplychainView';
import { Wrapper } from '../../components/modules/wrapper';

export const LogisticsSupplychainContainer = () => {
  return (
    <Wrapper>
      <LogisticsSupplychainView />
    </Wrapper>
  );
};
