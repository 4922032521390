import React from 'react';
import { responsibleemployer } from '../../../assets/images';
import { employer } from '../../../assets/icons';

const ResponsibleEmployer = () => {
  return (
    <div>
      <header
        className='h-[60vh] lg:h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${responsibleemployer})` }}
      >
        <div className='bg-black bg-opacity-60 w-screen h-[60vh] lg:h-[80vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <img src={employer} alt='' className='md:h-32 h-20' />
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            Responsible Employer
          </div>
        </div>
      </header>
    </div>
  );
};

export default ResponsibleEmployer;
