import React from 'react';
import { mgt } from '../../../assets/images';

const Ali = () => {
  const ali = [
    {
      id: 1,
      title: 'A Visionary Leader',
      description:
        ' Alhaji Ali Tatabe’s journey in the corporate world has been marked by visionary leadership. His ability to anticipate industry trends and steer the company in the right direction has been instrumental in positioning Tatabe Global Services Ltd as a trailblazer in multiple industries.',
    },
    {
      id: 2,
      title: 'Expertise in Oil and Gas',
      description:
        ' With extensive expertise in the oil and gas sector, Alhaji Ali Tatabe has overseen the development of strategic initiatives that have propelled the company’s presence in this critical industry. Under his guidance, Tatabe Global Services Ltd has consistently delivered reliable, high-quality solutions for exploration, production, and distribution.',
    },
    {
      id: 3,
      title: 'Supply Chain and Logistics Maestro',
      description:
        ' Alhaji Ali Tatabe’s proficiency in supply chain and logistics management has been instrumental in streamlining operations and optimizing efficiency. His strategic insights have facilitated the seamless movement of goods, ensuring clients’ needs are met with precision and reliability.',
    },
    {
      id: 4,
      title: 'Maritime and Shipping Luminary',
      description:
        ' In the maritime and shipping sectors, Alhaji Ali Tatabe’s leadership has seen the company expand its footprint and capabilities. His commitment to safety, compliance, and sustainability has elevated Tatabe Global Services Ltd’s status as a trusted partner in the transportation of goods through waterborne routes.',
    },
    {
      id: 5,
      title: 'Distribution and Manufacturing Maven',
      description:
        ' As a distribution and manufacturing expert, Alhaji Ali Tatabe has overseen the development of innovative solutions that cater to a diverse range of industries. His dedication to quality assurance and client-centric approaches has fortified the company’s reputation as a go-to provider of essential products.',
    },
    {
      id: 6,
      title: 'A Commitment to Excellence',
      description:
        'Throughout his career, Alhaji Ali Tatabe has consistently championed the principles of integrity, sustainability, and customer satisfaction. He leads by example, instilling these values into the core of Tatabe Global Services Ltd’s operations.',
    },
    {
      id: 7,
      title: 'A Vision for the Future',
      description:
        'Under Alhaji Ali Tatabe’s leadership, Tatabe Global Services Ltd is poised to continue its journey of growth, innovation, and responsible business practices. His vision for the future is one where the company continues to set new industry standards, shape sustainable progress, and exceed client expectations.',
    },
  ];
  return (
    <div className='pt-32 md:pt-48 pb-16 px-3 md:px-56 text-lg pattern'>
      <h2 className='font-semibold text-3xl md:text-4xl text-center text-red'>OUR LEADERSHIP</h2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-center my-12 md:my-16'>
        <img src={mgt} alt='' className='w-full h-96 md:border-l-8 border-l-4 border-l-red' />
        <div>
          <p className='font-bold text-2xl'>Alhaji Ali Tatabe</p>
          <p className='text-sm text-red-100'>CEO, Tatabe Global Services Ltd</p>
          <p className='mt-8'>
            Alhaji Ali Tatabe is a seasoned industry leader with over a decade
            of invaluable experience across a spectrum of dynamic sectors,
            including oil and gas, supply chain, shipping, maritime,
            distribution, and manufacturing. As the CEO of Tatabe Global
            Services Ltd, he has consistently demonstrated exemplary leadership,
            driving the company's growth, innovation, and commitment to
            excellence.
          </p>
        </div>
      </div>

      <div className='flex flex-col gap-6 '>
        {ali.map(({ id, title, description }) => (
          <div key={id} className='flex flex-col gap-4'>
            <p className='font-semibold text-xl text-red'>{title}</p>
            <p className='text=lg md:text-xl'>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ali;
