import React from 'react';
import { Ali } from '../../components/layout/Leadership';

const LeadershipView = () => {
  return (
    <div>
      <Ali />
    </div>
  );
};

export default LeadershipView;
