import React from 'react';
import { Input } from '../../widgets/form';
import { Field, Form, Formik } from 'formik';
import {
  call,
  envelope,
  linkedinRed,
  location,
  twitterRed,
} from '../../../assets/icons';
// import { contact } from '../../../assets/images';

const ContactForm = () => {
  return (
    <div className='grid md:grid-cols-2 grid-cols-1 px-3 sm:px-12 xl:px-32 gap-16 py-16 bg-[#EEEEEE]'>
      <div className='flex flex-col gap-6 text-base sm:text-lg'>
        <p className='font-bold text-3xl text-blue-600 sm:text-left text-center'>
          Ready to start a coversation?{' '}
          <span className='text-red'>Contact Us</span>
        </p>
        <div className='flex items-center gap-2'>
          <img src={location} alt='location' />
          <p>23 Itapeju Crescent, Off Wharf Road, Apapa, Lagos.</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={location} alt='location' />
          <p>55 Akanbi Onitiri Street, Eric Moore, Surulere, Lagos.</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={location} alt='location' />
          <p>1 Tatabe Street, Bulumkutu Abuja, Maiduguri, Borno State.</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={envelope} alt='mail' />
          <p>info@tatabeglobal.com</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={envelope} alt='mail' />
          <p>tatabeglobalservicesltd@gmail.com</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={call} alt='phone' />
          (+234) 8038669109
        </div>

        <div className='flex items-center gap-2'>
          <img src={twitterRed} alt='phone' />
          <p>@tatabeNG</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src={linkedinRed} alt='phone' />
          <p>@tatabeNG</p>
        </div>
      </div>
      <div className='h-full'>
        <Formik>
          <Form>
            <div className='md:grid-cols-2 grid-cols-1 grid gap-8'>
              <Input
                type='text'
                nameAndId='name'
                placeholder='Enter Name'
                label='Name'
              />

              <Input
                type='email'
                nameAndId='email'
                placeholder='Enter Email Address'
                label='Email Address'
              />
            </div>
            <div className='flex flex-col gap-2'></div>

            <Field
              as='textarea'
              placeholder='Write message'
              className='border-[0.5px] border-blue-300 bg-white h-48 rounded-sm w-full mt-3'
            />
            <div className='text-center md:text-left mt-12'>
              <button className='bg-red hover:bg-blue p-3 rounded-sm text-white w-full md:w-2/4 text-sm md:text-base'>
                Send Message
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ContactForm;
