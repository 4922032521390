import React from 'react';
import { scs } from '../../../assets/images';

const Impact = () => {
  return (
    <div className='px-3 sm:px-12 xl:px-56 py-16 flex flex-col gap-16'>
      <div className='flex flex-col gap-6 lg:pr-16'>
        <p className='text-3xl md:text-4xl font-semibold lg:text-left text-center'>
          Committed to making a positive impact in the world.
        </p>
        <p className='text-lg md:text-left text-center'>
          As a global organization, we understand the far-reaching impact we
          have on our planet and its inhabitants. Each of our facilities can
          directly influence the communities in which we operate. The suppliers
          we partner with expand our scope of influence. As a member of the
          Responsible Business Alliance, we collaborate with other leading
          global electronics companies to create sustainable business practices
          that protect our workers and our environment. We’re all in this
          together, which is why we make sure we act as a good neighbor.
        </p>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-center'>
        <div className='flex flex-col gap-6'>
          <p className='text-3xl md:text-4xl font-semibold md:text-left text-center'>
            We’re driven to uphold and improve human rights.
          </p>
          <div className='md:hidden block'>
            <img src={scs} alt='' />
          </div>
          <p className='text-lg text-center md:text-left'>
            At Tatabe, we’re dedicated to preserving and protecting human rights
            across the globe. All team members receive training through our Code
            of Conduct, and positions, such as commodity managers, receive
            additional training so they are equipped to identify possible
            concerns within the supply chain.
          </p>
          <p className='text-lg text-center md:text-left'>
            We extend our Code of Conduct expectations to our vendors,
            suppliers, service providers and other business partners to ensure
            they maintain the same high ethical standards for human rights. We
            conduct human rights training several times a year for our
            suppliers, and our supplier evaluation and qualification process
            helps us determine whether new and existing suppliers meet our
            criteria to become or remain a Tatabe business partner.
          </p>
          <div className='md:text-left text-center'>
            <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full lg:w-1/2'>
              HUMAN RIGHTS POLICY
            </button>
          </div>
        </div>
        <div className='hidden md:block'>
          <img src={scs} alt='' />
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-center'>
        <div className='hidden md:block'>
          <img src={scs} alt='' />
        </div>
        <div className='flex flex-col gap-6'>
          <p className='text-3xl md:text-4xl font-semibold text-center md:text-left'>
            Innovation depends on a sustainable future.
          </p>
          <div className='md:hidden block'>
            <img src={scs} alt='' />
          </div>
          <p className='text-lg text-center md:text-left'>
            We recognize that we have a responsibility to address the challenges
            our global community faces. From our operations to the influence
            over our supply chain, we hold ourselves accountable to safeguard
            our environment – for the sake of brighter ideas and a better
            tomorrow.
          </p>
          <div className='text-center md:text-left'>
            <button className='bg-[#5B1928] p-3 text-sm md:text-base rounded-sm text-white w-full lg:w-1/2'>
              OUR FOCUS ON SUSTAINABILITY
            </button>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-6'>
        <p className='text-3xl md:text-4xl md:text-left text-center font-semibold'>
          It matters where our minerals come from.
        </p>
        <p className='text-lg text-center md:text-left'>
          We make every effort to responsibly source materials throughout our
          global supply chain. We will not knowingly use minerals from corrupt
          origins to ensure that the only impact we’re making in people’s lives
          is a positive one. We hold our business partners accountable for the
          responsible sourcing of materials so as not to support corrupt or
          unfair treatment of people in the mining of minerals.
        </p>
        <div className='text-center md:text-left'>
          <button className='bg-[#5B1928] py-3 text-xs md:text-base rounded-sm text-white w-full lg:w-2/5'>
            LEARN ABOUT OUR CONFLICTS MINERALS PROGRAM
          </button>
        </div>
      </div>
    </div>
  );
};

export default Impact;
