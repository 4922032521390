import React from 'react';
import { manufacturing } from '../../../assets/images';

const Diverse = () => {
  return (
    <div>
      <header
        className='lg:h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex w-full '
        style={{ backgroundImage: `url(${manufacturing})` }}
      >
        <div className='bg-black bg-opacity-80 w-screen lg:h-[80vh] flex flex-col gap-8 justify-center  px-3 sm:px-12 xl:px-56 text-white py-12'>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-4xl md:leading-tight text-center lg:text-left'>
            Committed to a diverse and inclusive workplace.
          </div>
          <p className='text-lg md:text-left text-center'>
            With a foundation built on equal opportunity, diversity and respect,
            we’re committed to maintaining a professional work environment free
            of all forms of harassment and discrimination. We believe that
            through our collective uniqueness we achieve great things. We
            encourage all our employees, customers and neighbors to simply BE
            YOU!
          </p>
          <div className='text-center md:text-left'>
            <button className='bg-[#A0B350] hover:bg-[#b1c464] p-3 rounded-sm text-white w-full lg:w-1/3 text-sm md:text-base'>
              EXPLORE DIVERSITY & INCLUSION
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Diverse;
