import React from 'react';
import { why } from '../../../assets/images';
import { Link } from 'react-router-dom';
import { LEADERSHIP, WHY } from '../../../routes/CONSTANTS';

const Cards = () => {
  return (
    <div className=' pt-16 pb-24 px-5 sm:px-12 xl:px-64 text-gray bg-gray-400'>
      <div className='grid md:grid-cols-2 grid-cols-1 gap-12 md:gap-6 '>
        <div className='flex flex-col gap-4'>
          <img src={why} alt='' />
          <p className='text-2xl font-semibold'>Why Tatabe?</p>
          <p className='h-20'>
            When you’re choosing a partner to trust with getting your product to
            market, everything is on the line. We take that responsibility
            seriously. Here’s why we believe Tatabe is the best choice you can
            make
          </p>
          <Link
            to={WHY}
            className='p-2 bg-red text-white w-full md:w-1/3 hover:bg-[#0f1725] text-center md:text-left transition-all duration-300 rounded-sm mt-6'
          >
            Read more...
          </Link>
        </div>
        <div className='flex flex-col gap-4'>
          <img src={why} alt='' />
          <p className='text-2xl font-semibold'>Meet Our Leadership</p>
          <p className='h-20'>
            When you work with Tatabe, you’re partnering with people who are
            excited about collaborating with you to realize your vision. Here
            are the leaders who guide that mentality.
          </p>
          <Link
            to={LEADERSHIP}
            className='p-2 bg-red text-white w-full md:w-1/3 hover:bg-[#0f1725] text-center md:text-left transition-all duration-300 rounded-sm mt-6'
          >
            Read more...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cards;
