import React from 'react';

const Belt = () => {
  return (
    <div className='bg-blue-600 py-16 flex items-center justify-center'>
      <div className='flex md:flex-row flex-col-reverse items-center sm:gap-48 gap-4 px-2 md:px-5'>
        <button className='bg-red text-white py-2 px-2 md:px-6 rounded-sm hover:bg-blue'>Contact Us</button>
        <p className='text-base md:text-xl text-white'>Partner with us for all your business needs.</p>
      </div>
    </div>
  );
};

export default Belt;
