import React from 'react';
import { Link } from 'react-router-dom';
import { construction } from '../../../assets/icons';

const Placements = () => {
  const placement = [
    { id: 1, icon: construction, text: 'Plastic Manufacturing', link: '' },
    { id: 2, icon: construction, text: 'Shoe Manufacturing', link: '' },
    { id: 3, icon: construction, text: 'Tomato processing', link: '' },
    { id: 4, icon: construction, text: 'Recycling', link: '' },
    { id: 5, icon: construction, text: 'Solar & Inverters', link: '' },
  ];
  return (
    <div className='bg-gray-400 bg-opacity-90 flex flex-col md:flex-row md:gap-24 gap-6 px-3 sm:px-12 xl:px-48 py-16'>
      <div className='flex flex-col gap-8 w-full md:w-2/5'>
        <p className='text-blue-600 font-semibold text-3xl md:text-4xl text-center md:text-left'>
          Complete solutions for industrial product development.
        </p>
        <p className='text-black text-lg text-center md:text-left'>
          Tatabe Global is more than a manufacturer of industrial equipment. We'll
          partner with you as an extension of your own team, drawing on decades
          of experience and globally integrated capabilities to solve your most
          complex industrial product development challenges. No matter where you
          are in the product lifecycle.
        </p>
        <Link to="" className='bg-red hover:bg-white hover:text-blue transition-all duration-300 p-2 rounded-sm w-full md:w-1/2 text-base text-center text-white'>Explore Our Solutions</Link>
      </div>
      <div className='w-full md:w-3/5 grid md:grid-cols-3 grid-cols-2 items-center'>
        {placement.map(({ id, icon, text, link }) => (
          <div key={id} className=''>
            <img src={icon} alt='' />
            <Link to={link} className='text-black hover:text-red-100 text-lg'>{text}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Placements;
