import React from 'react';
import WhyView from './WhyView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const WhyContainer = () => {
  return (
    <Wrapper>
      <WhyView />
    </Wrapper>
  );
};
