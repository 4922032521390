import React, { useState } from 'react';
import { oilandgas } from '../../../assets/images';
import { minusCircle, plusCircle } from '../../../assets/icons';
import { Link } from 'react-router-dom';

const BusinessOilAndGas = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div className='flex flex-col items-center justify-center gap-16 sm:px-12 xl:px-52 px-3 py-8'>
      <div className='grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4 lg:gap-16'>
        <div className='hidden md:block'>
          <img src={oilandgas} alt='' />
        </div>
        <div className='flex flex-col gap-6'>
          <p className='font-semibold text-3xl md:text-4xl text-center md:text-left text-red'>
            Oil And Gas
          </p>
          <div className='md:hidden block'>
            <img src={oilandgas} alt='' />
          </div>
          <p className='text-lg md:text-2xl font-medium text-blue'>
            Manufacturing your product is not something we take lightly.{' '}
          </p>
          <p className='text-lg'>
            When you partner with Tatabe as your product manufacturing solutions
            provider, we understand that you’re putting a lot of trust in us.
            Trust to solve your most complex manufacturing and supply chain
            challenges. Trust to do what we say we’ll do, with zero defects.
            Trust to understand just how important your product will be in the
            lives of those who will use it. That’s why we’ve built our process
            around providing you the most innovative and dependable
            manufacturing solutions available.
          </p>
          <button className='p-2 bg-red  hover:bg-[#0f1725] transition-all duration-300 text-white rounded-sm w-full md:w-1/3'>
            Read Case Study
          </button>
        </div>
      </div>

      <div
        className={`flex flex-col gap-12 transition-all duration-1000 ${
          showContent ? 'flex' : 'hidden'
        }`}
      >
        <div className='flex flex-col md:flex-row gap-12 mt-16'>
          <div className='flex flex-col gap-6 w-full md:w-2/3'>
            <p className='font-semibold text-3xl'>
              Advanced global resources to bring highly complex products to
              life.
            </p>
            <p className='text-xl font-medium'>
              Choosing Tatabe to manufacture your product means you’re getting a
              true partner. We’ll take the time to understand your goals and
              work as an extension of your own team to find the smartest
              solution for your product. You’ll see an approach to manufacturing
              that focuses on innovation, continuous improvement and the
              flexibility to tailor our services to your exact needs — even if
              that means building out our capabilities to meet them. And you’ll
              benefit from a process that’s integrated with engineering, supply
              chain and aftermarket services to ensure the longevity of your
              product.
            </p>

            <Link
              to=''
              className='bg-red hover:bg-yellow text-white rounded-sm p-2 w-full md:w-1/4'
            >
              Contact us
            </Link>
          </div>

          <div className='w-full md:w-1/3 text-lg flex-col gap-2'>
            <p className='text-3xl font-semibold mb-4'>
              Manufacturing Capabilities
            </p>
            <li>Solar Panels</li>
            <li>Leather shoes and boots</li>
            <li>Plastics</li>
            <li>Tomato Processing</li>
            <li>Recycling</li>
          </div>
        </div>

        <div className='bg-gray-100 text-white p-2 md:p-6'>
          <div className='flex md:flex-row flex-col gap-6  w-full border-2 border-red md:pr-6'>
            <div className='text-xl bg-red flex items-center justify-center md:w-[10%] md:rotate-180 md:p-0 p-12'>
              <p className='md:rotate-90 md:tracking-wide'>OIL AND GAS</p>
            </div>

            <div className='flex flex-col font-light gap-6 sm:w-[45%] py-16'>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl'>
                  Experts In Micro-Electronics.
                </p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl'>
                  Experts In Micro-Electronics.
                </p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl'>
                  Experts In Micro-Electronics.
                </p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl'>
                  Experts In Micro-Electronics.
                </p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
              <div className='flex flex-col gap-6'>
                <p className='font-semibold text-2xl'>
                  Experts In Micro-Electronics.
                </p>
                <p className='text-lg'>
                  Technology keeps getting smaller and lighter, with higher
                  performance. Those are the requirements your products rely on
                  to stay competitive. At Tatabe, we’ve dedicated time and
                  resources to make sure we’re your best source — and the only
                  partner you’ll need — for complete microelectronics design,
                  engineering and manufacturing solutions.
                </p>
              </div>
            </div>

            <div className='sm:w-[45%] flex flex-col gap-6 text-lg py-16'>
              <p className='font-semibold text-2xl'>
                Enhancing your brand reputation and time to market.
              </p>
              <p>
                The Tatabe supply chain team applies four key processes to every
                engagement to ensure your supply chain is protected and your
                time-to-market needs are met.
              </p>
              <li>
                ENGINEERING INTEGRATION.<br></br> Collaboration with our
                engineering teams at the front end to identify the best
                strategic partners for your product and provide a customized
                solution for your needs.
              </li>
              <li>
                ESTABLISHING SHARED GOALS.<br></br> Working together to design
                solutions that are unique to you and your end customer.
              </li>
              <li>
                PROACTIVE MITIGATION.<br></br> Providing ongoing supply chain
                continuity through the use of proprietary risk mitigation tools.
              </li>
              <li>
                FULL LIFECYCLE SUPPORT.<br></br> Dedicated resources ready to
                support you from the initial concept to servicing the product in
                the market.
              </li>
              <p>
                Our flexible process allows us to identify, quantify, prioritize
                and mitigate any risks to your supply chain, so you can get your
                product into your customers’ hands in the most efficient and
                reliable way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='flex flex-col justify-center items-center gap-2 cursor-pointer'
        onClick={() => {
          setShowContent(!showContent);
        }}
      >
        <img src={showContent ? minusCircle : plusCircle} alt='' />
        <p className='text-red'>{showContent ? 'COLLAPSE' : 'EXPAND'}</p>
      </div>
    </div>
  );
};

export default BusinessOilAndGas;
