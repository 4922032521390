import React from 'react';

const Development = () => {
  return (
    <div className='flex flex-col gap-8 items-center justify-center px-3 sm:px-12 xl:px-56 py-16 bg-gray-400'>
      <p className='text-2xl md:text-4xl font-semibold text-center'>
        Integrated supply chain, oil & gas and manufacturing support to realize your
        most visionary products.
      </p>
      <p className='text-lg md:text-2xl text-center'>
        When you’re developing a highly complex product, you need a partner you
        can trust. For more than four decades, Tatabe has helped some of the
        world’s most respected companies take their products from the first seed
        of an idea all the way to a leader in the market. Our customers trust us
        because we do what we say—and we come through, even when others can’t.
      </p>
      <p className='text-lg md:text-xl text-center'>
        Engage with Tatabe anywhere along your product development lifecycle.
        When you do, you’ll get a global partner over 400-strong, working as
        an extension of your own team. Our focus is to fully understand your
        needs, from the complexities of sourcing, to technical engineering, to
        the cost demands of your industry. Whether we’re engineering a solution,
        figuring out complex manufacturing, or guiding your idea all the way
        through—you’ll always get big-picture thinking and transparency like
        you’ve never seen in this industry. You’ll tap into the creativity,
        determination and expertise that has given Tatabe a reputation as a
        leader in complex product development. But most of all, you’ll get our
        hearts and minds, dedicated to making your idea a reality.
      </p>
    </div>
  );
};

export default Development;
