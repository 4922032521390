import React from 'react';
import {
  AboutHero,
  Cards,
  Clients,
  AboutValues,
  ValuesCarousel,
  AboutMap,
} from '../../components/layout/About';
import { Belt } from '../../components/layout/Businesses';
import { Businesses, OurBlog } from '../../components/layout/Home';

const AboutView = () => {
  return (
    <div>
      <AboutHero />
      <Belt />
      <Businesses />
      <Cards />
      <AboutValues />
      <ValuesCarousel />
      <AboutMap />
      <Clients />
      <OurBlog />
    </div>
  );
};

export default AboutView;
