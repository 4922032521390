const SVG_PATH = '/assets/svg';
export const construction = `${SVG_PATH}/construction.svg`;
export const column = `${SVG_PATH}/column.svg`;
export const plusCircle = `${SVG_PATH}/plusCircle.svg`;
export const minusCircle = `${SVG_PATH}/minusCircle.svg`;
export const cma = `${SVG_PATH}/cma.svg`;
export const hapag = `${SVG_PATH}/harpag.svg`;
export const mrs = `${SVG_PATH}/mrs.jpg`;
export const linkedin = `${SVG_PATH}/linkedin.svg`;
export const linkedinRed = `${SVG_PATH}/linkedin-red.svg`;
export const twitter = `${SVG_PATH}/twitter.svg`;
export const twitterRed = `${SVG_PATH}/twitter-red.svg`;

// statistsics
export const clients = `${SVG_PATH}/clients.svg`;
export const calendar = `${SVG_PATH}/calendar.svg`;
export const products = `${SVG_PATH}/products.svg`;
export const briefcase = `${SVG_PATH}/briefcase.svg`;

export const peoplegroup = `${SVG_PATH}/peoplegroup.svg`;
export const handholdinghand = `${SVG_PATH}/handholdinghand.svg`;
export const globe = `${SVG_PATH}/globe.svg`;
export const scale = `${SVG_PATH}/scale.svg`;
export const idea = `${SVG_PATH}/idea.svg`;
export const employer = `${SVG_PATH}/employer.svg`;

export const chevrondown = `${SVG_PATH}/chevrondown.svg`;
export const ceva = `${SVG_PATH}/ceva.svg`;

export const prev = `${SVG_PATH}/prev.svg`;
export const next = `${SVG_PATH}/next.svg`;
export const years = `${SVG_PATH}/years.svg`;
export const projects = `${SVG_PATH}/projects.svg`;

export const ecoearth = `${SVG_PATH}/ecoearth.svg`;
export const community = `${SVG_PATH}/community.svg`;

export const menu = `${SVG_PATH}/menu.svg`;
export const close = `${SVG_PATH}/close.svg`;
export const caret = `${SVG_PATH}/caret.svg`;
export const arrowright = `${SVG_PATH}/arrowright.svg`;

// footer
export const location = `${SVG_PATH}/location.svg`;
export const envelope = `${SVG_PATH}/envelope.svg`;

export const call = `${SVG_PATH}/call.svg`;

export const recycle = `${SVG_PATH}/recycle.svg`;
export const recyclewhite = `${SVG_PATH}/recycle-white.svg`;
export const manufacturing = `${SVG_PATH}/manufacturing.svg`;
export const manufacturingwhite = `${SVG_PATH}/manufacturing-white.svg`;
export const oil = `${SVG_PATH}/oil.svg`;
export const oilwhite = `${SVG_PATH}/oil-white.svg`;
export const supplychains = `${SVG_PATH}/supplychains.svg`;
export const supplychainswhite = `${SVG_PATH}/supplychains-white.svg`;
export const flatbed = `${SVG_PATH}/flatbed.svg`;
export const flatbedwhite = `${SVG_PATH}/flatbed-white.svg`;
