// import React from 'react';
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
// import { vessel } from '../../../assets/images';

// const Hero = () => {
//   return (
//     <div>
//       <AwesomeSlider>
//         {/* <div className='bg-black bg-opacity-70 '>
//           <img src={vessel} alt='' className='h-[100vh]' />
//         </div> */}
//         <div
//           className=' h-[100vh] w-screen bg-fixed bg-no-repeat relative bg-cover bg-center '
//           style={{ backgroundImage: `url(${vessel})`, height: '100vh' }}
//         >
//           <div className='bg-black bg-opacity-70 w-screen h-[100vh] flex items-center md:justify-start justify-center'>
//             <h1 className=' text-white md:text-6xl text-4xl font-semibold md:pl-12 xl:pl-28'>
//               Contact Us
//             </h1>
//           </div>
//         </div>
//         <div className='bg-white text-red text-3xl'>2</div>
//         <div className='bg-white text-red text-3xl'>3</div>
//       </AwesomeSlider>
//     </div>
//   );
// };

// export default Hero;
// import React from 'react';
// import { sustainabilityHero } from '../../../assets/images';

// const Hero = () => {
//   return (
//     <div>
//       <header
//         className='h-[90vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
//         style={{ backgroundImage: `url(${sustainabilityHero})` }}
//       >
//         <div className='bg-black bg-opacity-70 w-screen h-[90vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
//           <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
//             We Help Create Products That Build A Better World.
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default Hero;

import React, { useRef, useState } from 'react';
import { homevid } from '../../../assets/videos'; // Import your video file

const Hero = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className='relative'>
      <div className='h-[90vh] w-full'>
        <video ref={videoRef} muted className='w-full h-full object-cover'>
          <source src={homevid} type='video/mp4' />
          {/* Add additional video formats (e.g., WebM, Ogg) for better browser compatibility */}
        </video>
      </div>
      <div
        className='absolute top-0 left-0 bg-blue bg-opacity-30 w-screen h-[90vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '
        onClick={togglePlay}
      >
        <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
          <span className='text-red'>At Tatabe Global, </span>
          We Help Create Products That Build A{' '}
          <span className='text-red'>Better World.</span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
