import React from 'react';
import { scs } from '../../../assets/images';

const GlobalManagement = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 px-3 sm:px-12 xl:px-56 py-16 bg-gray-400 items-center text-lg text-center md:text-left'>
      <div className='hidden md:block'>
        <img src={scs} alt='' />
      </div>
      <div className='flex flex-col gap-6'>
        <p className='text-3xl md:text-4xl font-semibold'>
          Adopting a global management system.
        </p>
        <div className='md:hidden block'>
          <img src={scs} alt='' />
        </div>
        <p className=''>
          We leverage a globally-recognized environmental management system to
          guide and monitor our progress. This framework helps us identify areas
          of risk and cost exposure at each unique location, allowing us to set
          site-specific objectives and targets to mitigate those risks. Through
          ISO 14001, we are able to verify our processes to validate their
          compliance to applicable laws, regulations and other requirements.
          That is why we are proud that all of our electronics manufacturing
          facilities globally are certified to ISO 14001.
        </p>
        <p className=''>
          Regular reviews of our sustainability program allow for timely
          adjustments to our overall sustainability strategy and align our goals
          and progress with the current business environment, long-term
          direction and emerging concerns of the company and its stakeholders.
        </p>

        <div className='text-center md:text-left'>
          <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full lg:w-2/4 text-sm md:text-base'>
            READ TATABE EHS POLICY
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalManagement;
