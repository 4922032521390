import React from 'react';
import { contactus } from '../../../assets/images';

const BlogHero = () => {
  return (
    <div>
      <header
        className='h-[50vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${contactus})` }}
      >
        <div className='bg-blue bg-opacity-80 w-screen h-[50vh] flex flex-col gap-8 justify-center px-6 sm:px-8 md:px-12 xl:px-56 '>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl  md:leading-tight text-center'>
            OUR <span className="text-red">BLOG</span>
          </div>
        </div>
      </header>
    </div>
  );
};

export default BlogHero;
