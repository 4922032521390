import React from 'react';
import { WhyHero } from '../../components/layout/Why';
import { Values } from '../../components/layout/About';

const WhyView = () => {
  return (
    <div>
      <WhyHero />
      <Values />
    </div>
  );
};

export default WhyView;
