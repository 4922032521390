import React from 'react';

const Terms = () => {
  return (
    <div className='text-[#0f1725] text-lg py-16 px-3 sm:px-12 lg:px-56'>
      <p>
        Tatabe Global Services Ltd. - Terms and Conditions Effective Date:
        [Insert Date]{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        1. Acceptance of Terms{' '}
      </h3>
      <p>
        By accessing or using the services provided by Tatabe Global Services
        Ltd. ("Tatabe," "we," "our," or "us"), you agree to comply with and be
        bound by these Terms and Conditions. If you do not agree with these
        terms, please do not use our services.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        2. Use of Services
      </h3>
      <p>
        You must be at least 18 years old to use our services. You agree to
        provide accurate, current, and complete information during the
        registration process and to update such information to keep it accurate,
        current, and complete.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        3. Service Description{' '}
      </h3>
      <p>
        Tatabe provides various services, including but not limited to
        transportation, logistics, manufacturing, and recycling. The details of
        the services, including pricing, are provided on our website or through
        direct communication. We reserve the right to modify, suspend, or
        discontinue any part of the services with or without notice.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        4. Payment and Billing
      </h3>
      <p>
        Payment for services is due as per the agreed terms between Tatabe and
        the client. Tatabe may require a down payment or advance payment before
        commencing services. Late payments may incur additional charges and may
        result in the suspension of services.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        5. Confidentiality{' '}
      </h3>
      <p>
        Both parties agree to maintain the confidentiality of any proprietary or
        confidential information disclosed during the course of business. This
        includes business strategies, financial information, and trade secrets.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        6. Intellectual Property
      </h3>
      <p>
        All content, trademarks, logos, and intellectual property on the Tatabe
        website are owned by Tatabe Global Services Ltd. You agree not to
        reproduce, distribute, modify, or create derivative works from any
        materials without our prior written consent.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        7. Limitation of Liability
      </h3>
      <p>
        Tatabe shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use,
        goodwill, or other intangible losses resulting from: Your use or
        inability to use the services Any unauthorized access to or use of our
        servers and/or any personal information stored therein Any interruption
        or cessation of transmission to or from our services Any bugs, viruses,
        Trojan horses, or the like transmitted to or through our services{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        8. Indemnification
      </h3>
      <p>
        You agree to indemnify, defend, and hold Tatabe and its affiliates,
        officers, directors, agents, and employees harmless from any claims,
        demands, losses, liabilities, and expenses, including attorney's fees,
        arising out of or in connection with your use of the services.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        9. Governing Law{' '}
      </h3>
      <p>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of [Your Jurisdiction], without regard to its
        conflict of law principles.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        10. Changes to Terms and Conditions{' '}
      </h3>
      <p>
        Tatabe reserves the right to modify or replace these Terms and
        Conditions at any time. Changes will be effective immediately upon
        posting on our website. Your continued use of the services after changes
        constitute acceptance of the updated Terms and Conditions.{' '}
      </p>
      <h3 className='text-center text-red font-semibold text-2xl lg:text-4xl mt-12 mb-8'>
        11. Contact Us
      </h3>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at tatabeglobalservicesltd@gmail.com.
      </p>
      <p>{new Date()}</p>
    </div>
  );
};

export default Terms;
