import React from 'react';
import { ContactForm, ContactHero } from '../../components/layout/Contact';
import ReportIssue from '../../components/layout/Sustainability/ReportIssue';

const ContactView = () => {
  return (
    <div>
      <ContactHero />
      <ReportIssue />
      <ContactForm />
    </div>
  );
};

export default ContactView;
