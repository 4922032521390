import React from 'react';
import { Link } from 'react-router-dom';
import { logoWhite } from '../../../assets/images';
import {
  // call,
  // envelope,
  linkedin,
  // location,
  twitter,
} from '../../../assets/icons';
import {
  ABOUT,
  BUSINESSES,
  CONTACT,
  HOME,
  PRIVACY,
  TERMS,
} from '../../../routes/CONSTANTS';

const Footer = () => {
  const companyLinks = [
    { id: 1, link: 'About us', url: ABOUT },
    { id: 2, link: 'Our Businesses', url: BUSINESSES },
    { id: 3, link: 'Contact us', url: CONTACT },
  ];
  const businessLinks = [
    { id: 1, link: 'Logistics & Haulage', url: '' },
    { id: 2, link: 'Terminal & Port Operations', url: '' },
    { id: 3, link: 'Oil and Gas', url: '' },
    { id: 4, link: 'Plastic Manufacturing', url: '' },
    { id: 5, link: 'Shoe Manufacturing', url: '' },
    { id: 6, link: 'Tomato Processing', url: '' },
    { id: 7, link: 'Import & Export', url: '' },
  ];
  const socialLinks = [
    { id: 1, link: '', icon: twitter },
    { id: 2, link: '', icon: linkedin },
  ];
  return (
    // [#0A0F18]
    <div className='bg-[#0f1725] text-white md:py-12 py-8 xl:px-20 sm:px-12 px-3 text-sm tracking-wide'>
      <div className='grid grid-cols-1 lg:grid-cols-4 sm:gap-2 lg:gap-6 gap-6 pb-8'>
        <Link to={HOME} className='md:hidden flex h-10 md:h-12 md:px-4'>
          <img src={logoWhite} alt='logo' />
        </Link>
        <div className='flex flex-col gap-4 lg:gap-8'>
          <h3 className='font-semibold text-lg lg:mt-0 mt-12 text-red-100'>
            Company
          </h3>
          {companyLinks.map((link) => (
            <Link
              to={link.url}
              key={link.id}
              className='hover:text-red-200 transition-all duration-300'
            >
              {link.link}
            </Link>
          ))}
        </div>
        <div className='flex flex-col gap-4 lg:gap-8'>
          <h3 className='font-semibold text-lg lg:mt-0 mt-12 text-red-100'>
            Business
          </h3>
          {businessLinks.map((link) => (
            <Link
              to={link.url}
              key={link.id}
              className='hover:text-red-200 transition-all duration-300'
            >
              {link.link}
            </Link>
          ))}
        </div>
        <div className='flex flex-col gap-8'>
          <h3 className='font-semibold text-lg lg:mt-0 mt-12 text-red-100'>
            General Enquiry
          </h3>
          <div className='flex gap-4'>
            {/* <img src={call} alt='' /> */}
            <p>(+234) 8038669109</p>
          </div>
          <div className='flex gap-4'>
            {/* <div>
              <img src={envelope} alt='' />{' '}
            </div> */}
            <div className='flex flex-col gap-4 md:gap-2'>
              <a
                href='mailto:info@tatabeglobal.com'
                className='hover:text-red-200 transition-all duration-500'
              >
                info@tatabeglobal.com
              </a>
              <a
                href='mailto:tatabeglobalservicesltd@gmail.com'
                className='hover:text-red-200 transition-all duration-500'
              >
                tatabeglobalservicesltd@gmail.com
              </a>
            </div>
          </div>

          <div className='flex gap-4'>
            {/* <div className=''>
              <img src={location} alt='' />
            </div> */}
            <div className='flex flex-col gap-4 md:gap-2'>
              <p>23 Itapeju Crescent, Off Wharf Road, Apapa, Lagos</p>
              <p>55 Akanbi Onitiri Street, Eric Moore, Surulere, Lagos</p>
              <p>1 Tatabe Street, Bulumkutu Abuja, Maiduguri, Borno</p>
            </div>
          </div>
          <div className='flex gap-4'>
            {socialLinks.map(({ id, icon, link }) => (
              <a href={link} key={id}>
                <img src={icon} alt='' />
              </a>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-4 md:gap-8 lg:mt-0 mt-12'>
          <p className='text-lg text-red-100'>Newsletter</p>
          <p>Join our weekly mailing list</p>
          <div className='flex w-full'>
            <input type='text' className='p-3 w-4/6' />
            <div
              type='button'
              className='bg-red hover:bg-red-100 cursor-pointer md:text-base text-sm text-white px-2 sm:px-1 py-4 xl:p-4 transition-all duration-300 rounded-r-sm w-2/6'
            >
              SUBSCRIBE
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col gap-8'>
        <div className='h-[0.5px] w-full bg-red-200'></div>
        <div className='flex-col md:flex-row flex justify-between md:items-center'>
          <Link to={HOME} className='hidden md:flex h-12'>
            {' '}
            <img src={logoWhite} alt='logo' />
          </Link>
          <div className='flex lg:flex-row flex-col-reverse lg:gap-28 gap-4 '>
            <p className='md:text-sm text-xs text-red-200'>
              © {new Date().getFullYear()} Tatabe Global Services Ltd. All
              rights reserved.
            </p>
            <div className='flex item-center gap-4'>
              <Link
                to={PRIVACY}
                className='hover:text-red-200 transition-all duration-500'
              >
                Privacy policy
              </Link>
              <Link
                to={TERMS}
                className='hover:text-red-200 transition-all duration-500'
              >
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
