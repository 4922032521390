import React from 'react';
import SustainingServicesView from './SustainingServicesView';
import { Wrapper } from '../../components/modules/wrapper';

export const SustainingServicesContainer = () => {
  return (
    <Wrapper>
      <SustainingServicesView />
    </Wrapper>
  );
};
