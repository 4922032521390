export const blogPosts = [
  {
    id: 1,
    title:
      'Revolutionizing Agriculture: Inside TataBe’s Tomato Processing Factory',
    description:
      'In the heart of Nigeria’s agricultural landscape, TataBe Global Services Ltd has set a new standard for innovation and efficiency. This article takes you on an exclusive journey inside...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 2,
    title:
      'Beyond Footwear: The Art and Craftsmanship Behind Leather Shoes and Boots Production',
    description:
      'Leather shoes and boots, more than mere accessories, are the embodiment of artistry, tradition, and innovation. This article delves into the  intricate world of leather footwear production...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 3,
    title:
      'Beyond Profit: TataBe Global Services Ltd’s CSR Initiatives in Local Communities',
    description:
      'In today’s corporate landscape, social responsibility goes beyond profit margins; it encompasses a commitment to giving back to the communities that nurture businesses...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 4,
    title: 'Cement and Pop: Navigating Nigeria’s Construction Industry',
    description:
      'Nigeria’s construction industry stands as a testament to the nation’s rapid growth and development. Among the vital components of this sector are cement and pop (plaster of Paris), materials...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 5,
    title:
      'From Concept to Consumer: Understanding the Manufacturing Process of Solar Panels ',
    description:
      'Solar energy stands as a beacon of sustainable power, illuminating the path towards a greener future. At the heart of harnessing solar energy lies the intricate process of...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 6,
    title:
      'Innovative Solutions in Container Terminal Management: Ensuring Smooth Operations',
    description:
      'Container terminals serve as vital hubs in the global supply chain, facilitating the movement of goods across continents. In this article, we explore the world of container...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 7,
    title:
      'Trucking, Barging, and Vessel Chartering: Meeting the Demands of Nigeria’s Logistics Landscape',
    description:
      'Nigeria’s bustling economic activities require a robust logistics network to ensure the seamless movement of goods across the nation. In this dynamic landscape...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 8,
    title:
      'The Economic Impact of TataBe Global Services Ltd: Fostering Growth and Development in Nigeria ',
    description:
      'In the vibrant tapestry of Nigeria’s economy, TataBe Global Services Ltd stands as a cornerstone, fostering growth, innovation, and development. This article will...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 9,
    title:
      'Exploring Nigeria’s Oil and Gas Sector: Challenges and Opportunities',
    description:
      'Nigeria’s oil and gas sector, a cornerstone of the nation’s economy, is a dynamic and complex industry. This article delves into the challenges and opportunities...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 10,
    title:
      'Fostering Innovation: Research and Development in TataBe Global Services Ltd ',
    description:
      'In the dynamic landscape of global business, innovation is the key to staying ahead. For TataBe Global Services Ltd, the spirit of innovation is not just a mantra...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 11,
    title:
      'Fueling Progress: Managing Diesel and Petrol Supply Chains in Nigeria',
    description:
      'Fuel, the lifeblood of modern society, drives progress and powers economies. In Nigeria, a nation in constant motion, managing the supply chains of diesel and petrol is...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 12,
    title:
      'A Step Towards Greener Earth: Plastic Recycling Initiatives in TataBe Global Services Ltd',
    description:
      ' In an era where environmental consciousness is paramount, TataBe Global Services Ltd stands as a pioneer in the journey towards a greener Earth. This article explores...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 13,
    title:
      'Navigating Challenges in International Trade: A Comprehensive Guide for Businesses ',
    description:
      'Engaging in international trade offers lucrative opportunities for businesses but also comes with a unique set of challenges. From cultural differences to...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 14,
    title:
      'Optimizing Supply Chain Efficiency: A Guide to Streamlining Trucking, Barging, and Vessel Chartering',
    description:
      'In today’s global marketplace, efficient supply chain management is crucial for businesses to remain competitive and meet customer demands. For companies...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 15,
    title:
      'Powering Nigeria: The Role of Solar Panels in the Energy Revolution',
    description:
      'Nigeria, a country blessed with abundant sunlight, is at the forefront of the renewable energy revolution. Solar power, harnessed through advanced...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 16,
    title:
      'From Raw Material to Final Product: Journey Inside Our Plastic Manufacturing Factory',
    description:
      'Step inside the heart of TataBe Global Services Ltd’s plastic manufacturing factory, where innovation and precision converge to create essential...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 17,
    title:
      'Sustainability in Action: How TataBe Global Services Recycles Plastics to Preserve the Environment ',
    description:
      'Plastic pollution poses a significant threat to our environment, but innovative companies like TataBe Global Services Ltd are taking proactive steps to...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 18,
    title:
      'The Future of Sustainable Manufacturing: Innovations in Plastic Recycling and Repurposing',
    description:
      'In the wake of environmental consciousness, businesses worldwide are embracing sustainable practices, especially in the manufacturing sector. TataBe Global...',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 19,
    title:
      'Sustainable Packaging Solutions: Reducing Environmental Impact in Manufacturing ',
    description:
      'In an era where environmental consciousness is paramount, businesses are redefining their practices to minimize their ecological footprint. One significant area of focus is sustainable',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
  {
    id: 20,
    title:
      'The Role of Technology in Modern Trade and Distribution: A Case Study of TataBe Global Services Ltd ',
    description:
      'In the digital age, technology has become the driving force behind modern trade and distribution, reshaping supply chains, enhancing efficiency',
    link: '',
    tag: 'Logistics',
    date: '23rd September, 2023',
  },
];
