import React from 'react';
import { Privacy, PrivacyHero } from '../../components/layout/Privacy';

const PrivacyView = () => {
  return (
    <div>
        <PrivacyHero />
      <Privacy />
    </div>
  );
};

export default PrivacyView;
