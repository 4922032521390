import React from 'react';
import { Link } from 'react-router-dom';
import { BLOG, CONTACT } from '../../../routes/CONSTANTS';

const Tag = () => {
  return (
    <div className='bg-gray-100 flex md:flex-row flex-col items-center justify-center gap-4 md:p-4 px-1 py-4'>
      <p className='text-white'>Looking for more industrial insights?</p>
      <div className='flex items-center justify-center gap-2 md:gap-4 w-full'>
        <Link
          to={BLOG}
          className='bg-red hover:bg-red-100 p-2 rounded-sm text-white md:w-40 w-1/2 text-center md:text-base text-sm'
        >
          See Our Blog
        </Link>
        <Link
          to={CONTACT}
          className='bg-[#FFB300] hover:bg-red-100 p-2 rounded-sm text-white md:w-40 w-1/2 text-center md:text-base text-sm'
        >
          Contact Our Team
        </Link>
      </div>
    </div>
  );
};

export default Tag;
