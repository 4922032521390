import React from 'react';
import {
    DistributionContact,
  DistributionInsights,
  DistributionMgtHero,
  DistributionMgtTag,
  DistributionServices,
  DistributionSupport,
} from '../../components/layout/DistributionMgt';

const DistributionManagementView = () => {
  return (
    <div>
      <DistributionMgtHero />
      <DistributionMgtTag />
      <DistributionServices />
      <DistributionSupport />
      <DistributionInsights />
      <DistributionContact />
    </div>
  );
};

export default DistributionManagementView;
