import { BrowserRouter } from 'react-router-dom';
import RouterConfig from './routes/RouterConfig';
import './index.css';

function App() {
  return (
    <div className='font-Poppins'>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </div>
  );
}

export default App;
