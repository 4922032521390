import React from 'react';
import Wrapper from '../../components/modules/wrapper/Wrapper';
import TermsView from './TermsView';

export const TermsContainer = () => {
  return (
    <Wrapper>
      <TermsView />
    </Wrapper>
  );
};
