import React from 'react';

const WorkingTogether = () => {
  return (
    <div className='px-3 sm:px-12 xl:px-56 py-16 flex flex-col gap-6 lg:mr-20'>
      <p className='text-3xl md:text-4xl font-semibold lg:text-left text-center'>
        Working together to care for the communities we call home.
      </p>
      <p className='text-lg md:text-left text-center'>
        Tatabe looks to share with our communities as we prosper. Each year, we
        contribute to non-profit causes in our communities and encourage
        employee volunteerism. Helping to improve the places where we do
        business has a positive impact on everything we do. It supports a
        continuous cycle of well-being that doesn't begin or end at our
        facilities.
      </p>
      <p className='text-lg md:text-left text-center'>
        We give to local charities that enhance innovation, promote
        technology-related educational programs (STEM) and preserve the quality
        of life in communities in which our teammates reside – what’s important
        to our teammates is important to us. Through monetary donations and the
        promotion of volunteerism, the Tatabe Charitable Foundation gives us all
        a chance to work together to achieve something better.
      </p>
      <div className=" md:text-left text-center">
        <button className='bg-[#5B1928] hover:bg-[#6e303e] p-3 rounded-sm text-white w-full lg:w-1/3'>
          OUR FOCUS ON CHARITABLE GIVING
        </button>
      </div>
    </div>
  );
};

export default WorkingTogether;
