import React from 'react';
import TomatoView from './TomatoView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const TomatoContainer = () => {
  return (
    <Wrapper>
      <TomatoView />
    </Wrapper>
  );
};
