import React from 'react';

const Principles = () => {
  return (
    <div className='bg-gray-400 flex flex-col gap-6 py-16 px-3 sm:px-12 xl:px-56 md:pr-20'>
      <h3 className='text-3xl md:text-4xl text-center lg:text-left font-semibold'>Ensuring our principles are upheld across our supply chain.</h3>
      <p className='text-center md:text-left text-lg'>
        Our Supplier Code of Conduct is distributed and made available to all
        suppliers. In this Code, we share our expectations for suppliers to read
        and conform to the Responsible Business Alliance (RBA) Code of Conduct.
        The standards established in the Code of Conduct reference international
        norms and standards, including the Universal Declaration of Human
        Rights, ILO International Labor Standards, and OECD Guidelines for
        Multinational Enterprises.
      </p>
      <p className='text-center md:text-left text-lg'>
        Tatabe asks our suppliers to join us in our efforts in aligning to
        responsible business practices by registering with the RBA. We
        additionally require our major suppliers to complete the RBA social
        responsibility assessment each year and, through the online platform, we
        monitor supplier conformance and work with our suppliers on any areas of
        concern.
      </p>
    </div>
  );
};

export default Principles;
