import React from 'react';
import { Link } from 'react-router-dom';
import {
  recycling,
  plasticManufacturing,
  shoeManufacturing,
  solarManufacturing,
  tomatoProcessing,
} from '../../../assets/images';

const Cards = () => {
  const keyMarkets = [
    {
      id: 1,
      image: plasticManufacturing,
      name: 'Plastic Manufacturing',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
    {
      id: 2,
      image: shoeManufacturing,
      name: 'Shoe Manufacturing',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
    {
      id: 3,
      image: tomatoProcessing,
      name: 'Tomato Processing',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
    {
      id: 4,
      image: recycling,
      name: 'Recycling',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
    {
      id: 5,
      image: solarManufacturing,
      name: 'Solar & Inverters',
      text: 'sunt commodi, magni quas reiciendis earum, vero nulla officiis asperiores, nihil amet deleniti est culpa vel. Aperiam eaque odio ut aspernatur?',
      link: '',
    },
  ];
  return (
    <div className='px-3 sm:px-12 lg:px-36 text-gray'>
      <div className='grid md:grid-cols-5 grid-cols-1 gap-8 xl:gap-6 '>
        {keyMarkets.map(({ id, image, name, text, link }) => (
          <div
            className='flex flex-col gap-4 sm:gap-2 xl:gap-4 cursor-pointer overflow-hidden pb-2  bg-white rounded-md shadow-md'
            key={id}
          >
            <img
              src={image}
              alt=''
              className='rounded-t-md md:h-40 h-56 transform transition-transform duration-300 hover:scale-110'
            />
            <p className='text-xl font-semibold text-center px-2 text-blue-600'>{name}</p>
            <p className='h-28 mb-6 px-2 text-blue-600 text-center'>{text}</p>
            <Link
              to={link}
              className='p-2 bg-red text-white hover:bg-blue transition-all duration-300 w-[93%] rounded-sm mt-6 text-center mx-auto'
            >
              Read more
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
