import React from 'react';
import { manufacturing } from '../../../assets/images';

const ManufacturingHero = () => {
  return (
    <div>
      <header
        className='h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${manufacturing})` }}
      >
        <div className='bg-black bg-opacity-80 w-screen h-[80vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            Partnering With You To Bring Innovative Products To Life.
          </div>
        </div>
      </header>
    </div>
  );
};

export default ManufacturingHero;
