import React from 'react';
import ManufacturingView from './ManufacturingView';
import { Wrapper } from '../../components/modules/wrapper';

export const ManufacturingContainer = () => {
  return (
    <Wrapper>
      <ManufacturingView />
    </Wrapper>
  );
};
