import { useState } from 'react';
import { call, envelope, location } from '../../../assets/icons';

const ReportIssue = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  const accordionItems = [
    {
      title: 'For Telephone Reporting',
      content: (
        <div className='p-4 bg-white'>
          <div className='flex items-center gap-2 mt-2'>
            <img src={call} alt='phone' />
            <p className='text-blue-600 text-base sm:text-lg'>
              (+234) 8038669109
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'For Email Reporting',
      content: (
        <div className='p-4 bg-white flex flex-col gap-6'>
          <div className='flex items-center gap-2 mt-2'>
            <img src={envelope} alt='mail' />
            <p className='text-blue-600 text-base sm:text-lg'>
              info@tatabeglobal.com
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={envelope} alt='mail' />
            <p className='text-blue-600 text-base sm:text-lg'>
              tatabeglobalservicesltd@gmail.com
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'PO BOX & Address',
      content: (
        <div className='p-4 bg-white flex flex-col gap-6'>
          <div className='flex items-center gap-2 mt-2'>
            <img src={location} alt='location' />
            <p className='text-blue-600 text-base sm:text-lg'>
              23 Itapeju Crescent, Off Wharf Road, Apapa, Lagos.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={location} alt='location' />
            <p className='text-blue-600 text-base sm:text-lg'>
              55 Akanbi Onitiri Street, Eric Moore, Surulere, Lagos.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={location} alt='location' />
            <p className='text-blue-600 text-base sm:text-lg'>
              1 Tatabe Street, Bulumkutu Abuja, Maiduguri, Borno.
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className='py-16 px-3 sm:px-12 xl:px-56 flex flex-col gap-6 '>
      <h3 className='font-bold text-3xl md:text-4xl text-center text-blue-600'>
        Report an Issue.
      </h3>
      <div className=''>
        {accordionItems.map((item, index) => (
          <div key={index} className='mb-4'>
            <button
              className='w-full flex items-center justify-between p-4 text-xl bg-gray-400 hover:bg-gray-200 hover:text-white rounded-md'
              onClick={() => toggleAccordion(index)}
            >
              <span className='text-lg'>{item.title}</span>
              <span className='ml-2'>
                {openAccordion === index ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M5 15l7-7 7 7'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-4 w-4'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M19 9l-7 7-7-7'
                    />
                  </svg>
                )}
              </span>
            </button>
            {openAccordion === index && item.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportIssue;
