import React from 'react';
import { mapGray } from '../../../assets/images';

const Map = () => {
  return (
    <div className='bg-white w-full  py-16 p-3 sm:p-12 xl:p-28 sm:h-[60vh] lg:h-[80vh] flex flex-col lg:flex-row items-center justify-center gap-16'>
      <img src={mapGray} alt='' className='sm:h-[30vh] lg:h-[70vh]' />
      <div className='flex flex-col gap-4'>
        <p className='font-semibold text-3xl md:text-4xl text-center lg:text-left text-blue-600'>
          Wherever you need us. Across the globe.
        </p>
        <p className='text-lg text-center lg:text-left'>
          Worldwide engineering, manufacturing and support services. Global
          supply chain expertise. All working together to get you to market
          faster.
        </p>
        <button className='bg-red hover:bg-[#0f1725] transition-all duration-500 text-white p-3 w-full sm:w-2/3 xl:w-1/3 rounded-sm mx-auto lg:mx-0'>
          Our Locations
        </button>
      </div>
    </div>
  );
};

export default Map;
