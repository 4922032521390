import React from 'react';
import { sustainability } from '../../../assets/images';

const Report = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-16 items-center justify-center px-3 sm:px-12 xl:px-56 py-16 md:py-24 text-blue-600'>
      <div className='flex flex-col gap-6'>
        <h3 className='font-semibold text-3xl md:text-4xl text-center md:text-left'>
          Sustainability Report 2024
        </h3>
        <p className='text-lg text-center md:text-left'>
          Our inaugural Sustainability Report highlights our commitment to
          Environmental, Social and Governance (ESG) action and progress. We are
          inspired by the opportunity in front of us to innovate, operate and
          coordinate our efforts in order to combat the social and environmental
          impacts of climate change and to adopt more resilient and responsible
          business practices. ESG is at the heart of our strategy and vital to
          realizing our vision to help create the products that build a better
          world.
        </p>
        <div className='text-center md:text-left'>
          <button className='p-3 bg-red hover:bg-red-200 text-white rounded-sm w-full lg:w-1/2 text-base'>
            READ THE FULL REPORT
          </button>
        </div>
        <div>
          <p className='text-xs'>
            UN Sustainability Development Goals (SGDs) index{' '}
          </p>
          <p className='text-xs'>TCFD standard context index</p>
        </div>
      </div>
      <img src={sustainability} alt='' />
    </div>
  );
};

export default Report;
