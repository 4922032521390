import React from 'react';
import { Header } from '../header';
import { Footer } from '../footer';
// import { Nav } from '../navbar';

const Wrapper = ({ children }) => {
  return (
    <div>
      <Header />
      {/* <Nav /> */}
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Wrapper;
