import React from 'react';
import {
  LogSCCard,
  LogSCHero,
  LogSCMarket,
  LogSCPlacements,
} from '../../components/layout/LogisticsSupplychain';
import { AboutMap } from '../../components/layout/About';
import { OurBlog } from '../../components/layout/Home';
import { Belt } from '../../components/layout/Businesses';

const LogisticsSupplychainView = () => {
  return (
    <div>
      <LogSCHero />
      <LogSCMarket />
      <LogSCCard />
      <Belt />
      <LogSCPlacements />
      <AboutMap />
      <OurBlog background="patterngray" />
    </div>
  );
};

export default LogisticsSupplychainView;
