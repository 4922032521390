import React from 'react';
import BusinessesView from './BusinessesView';
import { Wrapper } from '../../components/modules/wrapper';

export const BusinessesContainer = () => {
  return (
    <Wrapper>
      <BusinessesView />
    </Wrapper>
  );
};
