import React from 'react';
import Cards from './Cards';

const ManufacturingSolutions = () => {
  return (
    // bg-gradient-to-b from-[#EEEEEE] via-[#EEEEEE] via-50% to-white
    <div className='flex flex-col items-center justify-center gap-12 py-16'>
      <p className='text-3xl md:text-4xl font-semibold text-center text-blue'>
        FOCUSED ON <span className='text-red'>KEY INDUSTRIAL MARKETS</span>.
      </p>
      <div>
        <Cards />
      </div>
    </div>
  );
};

export default ManufacturingSolutions;
