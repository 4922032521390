import React from 'react';
import {
  BusinessPractices,
  CircularEconomy,
  Commitment,
  CommunityPartner,
  CorporateGovernance,
  Diverse,
  Empower,
  Engage,
  GlobalCitizen,
  GlobalManagement,
  Highlights,
  Impact,
  Innovation,
  // Marketplace,
  Pillars,
  Principles,
  Report,
  ReportIssue,
  ResponsibleBusiness,
  ResponsibleEmployer,
  SustainabilityHero,
  WorkingTogether,
} from '../../components/layout/Sustainability';

const SustainabilityView = () => {
  return (
    <div>
      {/* <Marketplace /> */}
      <SustainabilityHero />
      <Report />
      <Pillars />
      <Commitment />
      <Innovation />
      <CircularEconomy />
      <Principles />
      <ResponsibleEmployer />
      <ResponsibleBusiness />
      <Engage />
      <ReportIssue />
      <Diverse />
      <Empower />
      <CommunityPartner />
      <WorkingTogether />
      <GlobalCitizen />
      <Impact />
      <CorporateGovernance />
      <Highlights />
      <GlobalManagement />
      <BusinessPractices />
    </div>
  );
};

export default SustainabilityView;
