// import React from 'react';
// import { contactus } from '../../../assets/images';

// const ContactHero = () => {
//   return (
//     <div>
//       <header
//         className='h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
//         style={{ backgroundImage: `url(${contactus})` }}
//       >
//         <div className='bg-black bg-opacity-80 w-screen h-[80vh] flex flex-col gap-8 justify-center px-6 sm:px-8 md:px-12 xl:px-56 '>
//           <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl  lg:leading-tight text-center'>
//             Contact Us
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default ContactHero;

import React from 'react';

const ContactHero = () => {
  return (
    <div className='bg-blue-600 h-[60vh] sm:py-32 py-16 px-3 sm:px-12 lg:px-24 grid '>
      <div className='text-blue flex flex-col gap-6 justify-center'>
        <h1 className='text-4xl md:text-6xl font-bold text-white'>
          Contact Us
        </h1>
        <p className='text-lg sm:text-2xl text-white w-full sm:w-1/2'>
          We'd love to hear from you. Have a question about{' '}
          <span className='text-red'>Tatabe</span>? Need to talk to customer
          service? Get in touch with us
        </p>
      </div>
    </div>
  );
};

export default ContactHero;
