import React from 'react';
import {
  Businesses,
  // Carousel,
  Excited,
  Foundation,
  Hero,
  Map,
  OurBlog,
  Statistics,
  Testimonials,
} from '../../components/layout/Home';
import { Partners } from '../../components/layout/About';


const HomeView = () => {
  return (
    <div>
      <Hero />
      <Excited />
      <Statistics />
      <Businesses />
      <Testimonials />
      <Map />
      <Foundation />
      <Partners />
      <OurBlog background='pattern' />
    </div>
  );
};

export default HomeView;
