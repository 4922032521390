import React from 'react';
import { factory, manufacturing } from '../../../assets/images';
import { Link } from 'react-router-dom';
import {
  LOGISTICS_SUPPLYCHAIN,
  MANUFACTURING,
  OILANDGAS,
  SUSTAINING_SERVICES,
  TRADE_DISTRIBUTION,
} from '../../../routes/CONSTANTS';
import { arrowright } from '../../../assets/icons';

const Businesses = () => {
  const businesses = [
    {
      id: 1,
      img: manufacturing,
      route: LOGISTICS_SUPPLYCHAIN,
      text: 'LOGISTICS & SUPPLY CHAIN',
    },
    {
      id: 2,
      img: factory,
      route: OILANDGAS,
      text: 'OIL AND GAS',
    },
    {
      id: 3,
      img: manufacturing,
      route: MANUFACTURING,
      text: 'MANUFACTURING',
    },
    {
      id: 4,
      img: factory,
      route: SUSTAINING_SERVICES,
      text: 'SUSTAINING SERVICES',
    },
    {
      id: 5,
      img: factory,
      route: TRADE_DISTRIBUTION,
      text: 'TRADE & DISTRIBUTION',
    },
  ];
  return (
    <div className='py-20'>
      <p className='font-bold text-3xl md:text-5xl text-center pb-12 text-blue-600'>
        OUR <span className='text-red'>MARKET SECTORS</span>
      </p>
      <div className='flex flex-wrap gap-12 lg:gap-2 items-center justify-center px-3 sm:px-12 lg:px-16'>
        {businesses.map(({ id, route, img, text, text2 }) => (
          <Link
            key={id}
            to={route}
            className='relative  rounded-sm group h-96 shadow-lg lg:w-1/6 md:w-2/5 w-1/1'
          >
            <img src={img} alt='' className='w-full aspect-[1/1] ' />
            <div className='absolute inset-0 bg-blue-600 hover:bg-red transition-all duration-500 hover:opacity-70 group-hover:top-0 top-2/3 rounded-b-sm'></div>
            <div className='absolute bottom-0 left-0 right-0 text-white p-4'>
              <p className='text-lg mb-4'>{text}</p>
              <img src={arrowright} alt='arrow right ' />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Businesses;
