import React from 'react';

const Engage = () => {
  return (
    <div className="bg-[#1B2A46] px-3 sm:px-12 xl:px-56 sm:pr-12 xl:pr-28 py-16 flex flex-col gap-6 text-white">
      <p className='text-3xl md:text-4xl text-center md:text-left font-bold'>Engaging our team members to build a better Tatabe.</p>
      <p className='text-lg text-center md:text-left'>
        We value our employees and strive to provide a healthy, safe and
        fulfilling work experience. Through our annual employee survey process,
        we are able to assess employee satisfaction, identify improvement
        opportunities and leverage suggestions from team members. Formal
        initiatives are generated based on the feedback we receive, both at an
        enterprise and site level.
      </p>
      <p className='text-3xl md:text-4xl text-center md:text-left font-bold mt-2'>Our Ethics Hotline is always open.</p>
      <p className='text-lg text-center md:text-left'>
        Anonymous reporting via phone, web or postal service through an
        independent vendor is available 24/7/365 and in over 200 languages. Our
        employees, business partners and other stakeholders may use this tool to
        report suspected or observed violations of our policies or legal
        requirements.
      </p>
      <p className='text-lg text-center md:text-left'>
        We demand the highest standard of ethical behavior and personal
        integrity from our own employees, guided by the Tatabe Code of Conduct
        and Business Ethics. We extend the same expectations to our business
        partners.
      </p>
    </div>
  );
};

export default Engage;
