import React, { useState, useRef, useEffect } from 'react';
import { logoRed } from '../../../assets/images';
import { Link } from 'react-router-dom';
import { HOME } from '../../../routes/CONSTANTS';
import { caret, close, menu } from '../../../assets/icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const navbarRef = useRef(null);

  const DropdownMenu = ({ items, activeItem, onClick }) => {
    return (
      <ul className=' absolute py-4 flex flex-col px-3 gap-2 sm:gap-6 w-full sm:w-72 bg-white shadow-lg '>
        {items.map((item, index) => (
          <li key={index} className=' '>
            <a
              href={item.link}
              className={`hover:text-red p-2 text-black  ${
                activeItem === item.name ? 'text-red' : ''
              }`}
              onClick={() => onClick(item.name)}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const dropdownItems = [
    { name: 'Logistics & Supply Chain', link: '/logistics-supplychain' },
    { name: 'Oil & Gas', link: '/oilandgas' },
    { name: 'Manufacturing', link: '/manufacturing' },
    { name: 'Trade & Distribution', link: '/trade-distribution' },
    { name: 'Sustaining Services', link: '/sustaining-services' },
  ];

  return (
    <nav
      ref={navbarRef}
      className={`fixed top-0 left-0 right-0 py-4 px-3 transition-all z-50 md:h-auto text-lg   ${
        isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
      }`}
    >
      <div className='container xl:px-20 sm:px-12 flex lg:flex-row flex-col md:justify-between'>
        <div className='flex justify-between items-center'>
          <Link to={HOME}>
            <img src={logoRed} alt='logo' className='md:h-12 h-10' />
          </Link>
          <div className='lg:hidden'>
            <button
              onClick={toggleNavbar}
              className={`${
                isScrolled ? 'text-black' : 'text-white'
              } flex justify-end`}
            >
              <img src={isOpen ? close : menu} alt='' />
            </button>
          </div>
        </div>
        <div
          className={`lg:flex transition-all duration-500 ease-in  absolute md:static left-0 ${
            isOpen ? ' top-2 right-0' : ' top-[-800px] right-0'
          } lg:items-center space-y-2 lg:space-y-0 lg:space-x-4`}
        >
          <ul
            className={`flex lg:flex-row flex-col gap-4 md:gap-4 p-5 md:relative absolute md:top-0 top-16 right-0 w-full py-8 md:h-16 h-[60vh] ${
              isScrolled ? 'text-black bg-white' : 'text-white'
            }`}
          >
            <li>
              <a
                href='/about'
                className={`${
                  isScrolled ? 'text-[#0A0F18]' : 'text-white'
                } hover:text-red-200  ${
                  activeItem === 'About' ? 'text-red-200' : ''
                }`}
                onClick={() => setActiveItem('About')}
              >
                About Us
              </a>
            </li>
            <li
              className='relative group'
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            >
              <button
                className={` flex items-center gap-2 ${
                  isScrolled ? 'text-[#0A0F18]' : 'text-white'
                } hover:text-red-200`}
              >
                Businesses{' '}
                <img
                  src={caret}
                  alt=''
                  className={` transition-all duration-300 ${
                    isOpen ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </button>
              {isOpen && (
                <DropdownMenu
                  items={dropdownItems}
                  className={`  ${
                    isScrolled ? 'text-[#0A0F18]' : 'text-white'
                  }`}
                  activeItem={activeItem}
                  onClick={(item) => setActiveItem(item)}
                />
              )}
            </li>
            <li>
              <a
                href='/sustainability'
                className={`${
                  isScrolled ? 'text-black' : 'text-white'
                } hover:text-red-200 ${
                  activeItem === 'Contact' ? 'text-red-200' : ''
                }`}
                onClick={() => setActiveItem('Contact')}
              >
                Sustainability
              </a>
            </li>

            <li>
              <a
                href='/'
                className={`${
                  isScrolled ? 'text-black' : 'text-white'
                } hover:text-red-200 ${
                  activeItem === 'Contact' ? 'text-red-200' : ''
                }`}
                onClick={() => setActiveItem('Contact')}
              >
                Tatabe Foundation
              </a>
            </li>
            <li>
              <a
                href='/contact'
                className={`${
                  isScrolled ? 'text-black' : 'text-white'
                } hover:text-red-200 ${
                  activeItem === 'Contact' ? 'text-red-200' : ''
                }`}
                onClick={() => setActiveItem('Contact')}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
