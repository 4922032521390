import React from 'react';

const DistributionServices = () => {
  const services = [
    {
      id: 1,
      title: 'Forward Logistics',
      text: 'Deliver your repaired, refurbished and revitalized products where they need to be, on time.',
    },
    {
      id: 2,
      title: 'Inventory Management',
      text: 'Ensure that you have the right parts, in the right quantities, where and when you need them.',
    },
    {
      id: 3,
      title: 'Configure-To-Order Manufacturing',
      text: 'Meet the individual needs of your customers—however complex they may be—with configure-to-order manufacturing services.',
    },
    {
      id: 4,
      title: 'Direct Order Fulfilment',
      text: 'Deliver on time. Provide a great customer experience. Simplify your process. We’ll ship revitalized products directly to your end customers',
    },
    {
      id: 5,
      title: 'Spare Parts Stocking and Parts Kitting',
      text: 'Stay prepared with the right level of spare parts—where you need them, set up and on hand to simplify your product repairs.',
    },
  ];
  return (
    <div className='px-3 sm:px-12 xl:px-56 py-16'>
      <h2 className='text-3xl md:text-5xl font-semibold text-center'>
        Our distribution management services
      </h2>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-8 md:mt-28'>
        {services.map(({ id, title, text }) => (
          <div key={id} className='flex flex-col gap-4 mt-12'>
            <div className='h-4 w-20 rounded-lg bg-[#A0B350]'></div>
            <p className='text-2xl font-semibold'>{title}</p>
            <p className='text-base'>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistributionServices;
