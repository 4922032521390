import React from 'react';
import { Link } from 'react-router-dom';

const DistributionMgtTag = () => {
  return (
    <div className='bg-gray-100 flex md:flex-row flex-col items-center justify-center gap-4 md:p-2 px-1 py-4'>
      <p className='text-white text-lg hidden md:block'>Ready to start a conversation?</p>

      <Link
        to='/contact'
        className='bg-[#A0B350] hover:bg-[#0f1725] text-white  rounded-sm text-center p-2 md:text-base text-sm'
      >
        CONTACT OUR TEAM
      </Link>
    </div>
  );
};

export default DistributionMgtTag;
