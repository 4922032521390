import React from 'react';
import ImportExportView from './ImportExportView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const ImportExportContainer = () => {
  return (
    <Wrapper>
      <ImportExportView />
    </Wrapper>
  );
};
