import React from 'react';
import CareerView from './CareerView';
import { Wrapper } from '../../components/modules/wrapper';

export const CareerContainer = () => {
  return (
    <Wrapper>
      <CareerView />
    </Wrapper>
  );
};
