import React from 'react';

const ManufacturingMarkets = () => {
  return (
    <div className='px-3 sm:px-20 xl:px-72 text-center flex flex-col gap-12 py-16 bg-gray-400'>
      <p className='text-3xl md:text-4xl text-gray font-semibold'>
        Helping industrial product companies develop products for a smarter
        world.
      </p>
      <p className='text-lg md:text-xl text-gray'>
        Technology is changing the way we interact with everything around us —
        the equipment, vehicles, machines and products we rely on. From
        semiconductor technology or industrial automation and robotics, to
        energy, transportation controls or self-service retail kiosks, there’s
        no shortage of opportunities to make those interactions smarter. And in
        a landscape this diverse, you need an industrial product development
        partner who understands the unique needs of your industry.
      </p>
    </div>
  );
};

export default ManufacturingMarkets;
