import React from 'react';
import { scs } from '../../../assets/images';

const Empower = () => {
  return (
    <div className='px-5 sm:px-12 xl:px-56 py-16 flex flex-col gap-16'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-center'>
        <div className='flex flex-col gap-6'>
          <p className='text-3xl md:text-4xl font-semibold text-center md:text-left'>
            Empowering employees to reach their full potential.
          </p>
          <div className='md:hidden block'>
            <img src={scs} alt='' />
          </div>
          <p className='text-lg text-center md:text-left'>
            Tatabe is only successful when each of our teammates succeed. At any
            level, and in any role, we believe each of us are leaders who have
            the potential to make a difference and behave in a way that inspires
            others to do their best work. We offer a variety of opportunities,
            programs and resources for our teammates so they can unlock their
            potential, explore areas of interest and have the opportunity to
            grow. We are passionate about investing in each of our teammates.
          </p>
          <div className='md:text-left text-center'>
            <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full lg:w-1/2 text-sm md:text-base'>
              HOW WE DEVELOP OUR PEOPLE
            </button>
          </div>
        </div>
        <div className='hidden md:block'>
          <img src={scs} alt='' />
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-center'>
        <div className='hidden md:block'>
          <img src={scs} alt='' />
        </div>
        <div className='flex flex-col gap-6'>
          <p className='text-3xl md:text-4xl font-semibold text-center md:text-left'>
            Protecting the well-being of our people.
          </p>
          <div className='md:hidden block'>
            <img src={scs} alt='' />
          </div>
          <p className='text-lg md:text-left text-center'>
            Well-being fuels inspiration, sparks creativity and drives
            adeptness. Whether in our manufacturing facilities or our design
            centers, we understand that a healthier, safer work environment
            fosters a happier, more dedicated workforce. And that means good
            things for our employees, families and communities around the world.
          </p>
          <p className='text-lg md:text-left text-center'>
            Our dedicated and talented team members are the cornerstone of
            everything we do and achieve at Tatabe. We support a holistic
            approach to employee well-being through healthy-living education and
            coaching and by sponsoring wellness initiatives. We understand that
            protecting and caring about the wellness of our people means
            ensuring they can also be well outside of our facilities.
          </p>
          <div className='md:text-left text-center'>
            <button className='bg-[#5B1928] p-3 rounded-sm text-white w-full lg:w-1/2 md:text-base text-sm'>
              OUR COMMITMENT TO SAFETY
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empower;
