import React from 'react';
import LeadershipView from './LeadershipView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const LeadershipContainer = () => {
  return (
    <Wrapper>
      <LeadershipView />
    </Wrapper>
  );
};
