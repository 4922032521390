import React from 'react';
import { Link } from 'react-router-dom';
import { construction } from '../../../assets/icons';

const LogSCPlacements = () => {
  const placement = [
    { id: 1, icon: construction, text: 'Heavy Trucking & Haulage', link: '' },
    { id: 2, icon: construction, text: 'Barge & Vessel Chattering', link: '' },
    { id: 3, icon: construction, text: 'Container Terminal Operations', link: '' },
    { id: 4, icon: construction, text: 'Import & Export (Global Trade)', link: '' }
  ];
  return (
    <div className=' bg-opacity-90 flex flex-col md:flex-row gap-6 md:gap-28 px-3 sm:px-12 xl:px-48 py-16'>
      <div className='flex flex-col gap-8 w-full md:w-2/5'>
        <p className='text-blue-600 font-semibold text-3xl md:text-4xl text-center md:text-left'>
          Complete solutions for logistics and supply chain operations.
        </p>
        <p className=' text-lg text-center md:text-left'>
          In today's dynamic business environment, efficient logistics and
          supply chain management are essential for success. At Tatabe, we
          understand the intricacies of moving goods seamlessly from one point
          to another. Whether it's heavy trucking, maritime transportation,
          container handling, or facilitating international trade, Tatabe the
          expertise and resources to handle it all.
        </p>
        <Link
          to=''
          className='bg-red hover:bg-white hover:text-blue transition-all duration-300 p-2 rounded-sm w-full md:w-1/2 text-base text-center text-white'
        >
          Explore Our Solutions
        </Link>
      </div>
      <div className='w-full md:w-3/5 grid grid-cols-2 items-center'>
        {placement.map(({ id, icon, text, link }) => (
          <div key={id} className=''>
            <img src={icon} alt='' />
            <Link to={link} className=' hover:text-red-100 text-lg'>
              {text}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogSCPlacements;
