import React from 'react';
import BlogCards from './BlogCards';
import BlogTags from './BlogTags';

const BlogLayout = () => {
  return (
    <div className='w-full flex flex-col lg:flex-row'>
      <BlogCards />
      <BlogTags />
    </div>
  );
};

export default BlogLayout;
