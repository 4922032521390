import React, { useState } from 'react';
import { scs } from '../../../assets/images';
import { minusCircle, plusCircle } from '../../../assets/icons';
import { Link } from 'react-router-dom';

const BusinessSolar = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div className='flex flex-col items-center justify-center gap-16 sm:px-12 xl:px-52 px-3 py-8'>
      <div className='grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4'>
        <div className='flex flex-col gap-6'>
          <p className='font-semibold text-3xl mdLtext-4xl text-center md:text-left'>Supply Chain Solutions</p>
          <div className='block md:hidden'>
          <img src={scs} alt='' />
        </div>
          <p className='text-lg md:text-2xl font-medium'>
            Personalized success delivered through innovative, efficient and
            scalable solutions.{' '}
          </p>
          <p className='text-lg'>
            Everything we do is driven by you. From the start of your engagement
            with Tatabe, you can expect a level of collaboration and
            transparency you won’t get anywhere else. We’re known for embracing
            your complex needs and finding creative ways to manage your entire
            supply chain.
          </p>
          <button className='p-2 bg-red hover:bg-[#0f1725] transition-all duration-300 text-white rounded-sm w-full md:w-1/3'>
            Read Case Study
          </button>
        </div>
        <div className='md:block hidden'>
          <img src={scs} alt='' />
        </div>
      </div>

      <div
        className={`flex flex-col gap-12 transition-all duration-1000 ${
          showContent ? 'flex' : 'hidden'
        }`}
      >
        <div className='flex flex-col md:flex-row gap-12 mt-16'>
          <div className='flex flex-col gap-6 w-full md:w-2/3'>
            <p className='font-semibold text-3xl'>
              Experienced in global supply chain complexity.
            </p>
            <p className='text-xl font-medium'>
              In an ever connected and competitive economy, delivering optimal
              supply chain solutions is about much more than simply getting your
              product where it needs to be on time. It’s about understanding
              your total supply chain through product launch and beyond. It
              means having the ability to proactively identify and mitigate
              risks to ensure your business objectives are met. We’ll work
              closely with you to design and implement a global supply chain
              solution that’s as flexible and scalable as you need it to be.
            </p>
            <p className='text-lg'>
              When you partner with Tatabe, you can be sure your product is in
              capable hands. With decades of experience and a proven track
              record in complex supply chain problem-solving, we’ve developed
              proprietary design, analysis and simulation tools that instill
              confidence in our ability to get your product to market on time.
              And we continue to invest in our teams and resources to take on
              your emerging supply chain needs.
            </p>
            <Link
              to=''
              className='bg-red hover:bg-yellow text-white rounded-sm p-2 w-full md:w-1/3'
            >
              Contact us
            </Link>
          </div>

          <div className='w-full md:w-1/3 text-lg flex-col gap-2'>
            <p className='text-3xl font-semibold mb-4'>
              Supply Chain Capabilities
            </p>
            <li>Design for supply chain</li>
            <li>Innovative sourcing</li>
            <li>Supply chain risk management</li>
            <li>Actionable analytics</li>
            <li>Managing global, complex supply chains</li>
          </div>
        </div>

        <div className='bg-gray-100 text-white p-2 md:p-6'>
          <div className='flex md:flex-row flex-col gap-6  w-full border-2 border-gray-300 md:pr-6'>
            <div className='text-xl bg-red flex items-center justify-center md:w-[10%] md:rotate-180 md:p-0 p-12'>
              <p className='md:rotate-90 md:tracking-wide'>SUPPLY CHAIN</p>
            </div>

            <div className='flex flex-col font-light gap-6 sm:w-[45%] py-16'>
              <p className='font-semibold text-2xl'>
                Dedicated to superior risk mitigation.
              </p>
              <p className='text-lg'>
                At Tatabe, we understand it’s our responsibility to manage your
                supply chain while minimizing cost, mitigating risk, and
                ensuring continuity of supply. Our unique approach includes
                Design for Supply Chain, where our supply chain experts work
                closely with our team of engineers to continuously identify
                opportunities for supply chain optimization in the design stage.
                We’ll then collaborate closely with you to qualify and implement
                recommendations to enhance your product’s quality while driving
                down cost.
              </p>
            </div>

            <div className='sm:w-[45%] flex flex-col gap-6 text-lg py-16'>
              <p className='font-semibold text-2xl'>
                Enhancing your brand reputation and time to market.
              </p>
              <p>
                The Tatabe supply chain team applies four key processes to every
                engagement to ensure your supply chain is protected and your
                time-to-market needs are met.
              </p>
              <li>
                ENGINEERING INTEGRATION.<br></br> Collaboration with our
                engineering teams at the front end to identify the best
                strategic partners for your product and provide a customized
                solution for your needs.
              </li>
              <li>
                ESTABLISHING SHARED GOALS.<br></br> Working together to design
                solutions that are unique to you and your end customer.
              </li>
              <li>
                PROACTIVE MITIGATION.<br></br> Providing ongoing supply chain
                continuity through the use of proprietary risk mitigation tools.
              </li>
              <li>
                FULL LIFECYCLE SUPPORT.<br></br> Dedicated resources ready to
                support you from the initial concept to servicing the product in
                the market.
              </li>
              <p>
                Our flexible process allows us to identify, quantify, prioritize
                and mitigate any risks to your supply chain, so you can get your
                product into your customers’ hands in the most efficient and
                reliable way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='flex flex-col justify-center items-center gap-2 cursor-pointer'
        onClick={() => {
          setShowContent(!showContent);
        }}
      >
        <img src={showContent ? minusCircle : plusCircle} alt='' />
        <p>{showContent ? 'COLLAPSE' : 'EXPAND'}</p>
      </div>
    </div>
  );
};

export default BusinessSolar;
