import React from 'react';
import { grayBg } from '../../../assets/images';
import { Input } from '../../widgets/form';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div>
      <header
        className='h-full bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full'
        style={{ backgroundImage: `url(${grayBg})` }}
      >
        <div className='bg-gray-200 bg-opacity-80 w-screen h-full  flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 py-16'>
          <div className=' h-full'>
            <h2 className='font-bold text-3xl md:text-5xl text-white text-center'>
              Contact Our Team
            </h2>
            <p className='text-lg text-white text-center my-6'>
              Ready to learn more? Share your information and our team will be
              in touch.
            </p>
            <Formik>
              <Form>
                <div className='md:grid-cols-3 grid-cols-1 grid gap-2'>
                  <Input
                    type='text'
                    nameAndId='firstName'
                    placeholder='First Name'
                    label='First Name'
                  />
                  <Input
                    type='text'
                    nameAndId='lastName'
                    placeholder='Last Name'
                    label='Last Name'
                  />
                  <Input
                    type='text'
                    nameAndId='companyName'
                    placeholder='Company Name'
                    label='Company Name'
                  />
                  <Input
                    type='text'
                    nameAndId='jobTitle'
                    placeholder='Job Title'
                    label='Job Title'
                  />
                  <Input
                    type='email'
                    nameAndId='email'
                    placeholder='Email Address'
                    label='Email Address'
                  />
                  <Input
                    type='number'
                    nameAndId='phone'
                    placeholder='phone'
                    label='Phone Number'
                  />
                </div>
                <div className='flex flex-col gap-2 mt-2'>
                  <div className='flex flex-col gap-2'>
                    <label className='' htmlFor='industry'>
                      <span className='text-base text-black'>Industry</span>
                    </label>
                    <Field
                      as='select'
                      placeholder='Industry'
                      className='border-[0.5px] border-black rounded-sm bg-white text-black p-2'
                    >
                      <option>Select an industry</option>
                      <option>Transportation</option>
                      <option>Industrial Equipment</option>
                      <option>Healthcare Management</option>
                      <option>Telecommunications</option>
                      <option>Construction</option>
                      <option>Life Sciences</option>
                      <option>Manufacturing</option>
                      <option>FMCG</option>
                    </Field>
                  </div>
                  <div className='flex flex-col gap-2'>
                    <label className='' htmlFor='region'>
                      <span className='text-base text-black'>Region *</span>
                    </label>
                    <Field
                      as='select'
                      placeholder='Region'
                      className='border-[0.5px] border-black rounded-sm bg-white text-black placeholder:text-black p-2'
                    >
                      <option>Choose a region</option>
                      <option>Africa</option>
                      <option>Americas</option>
                      <option>Asia</option>
                      <option>Europe</option>
                      <option>Middle East</option>
                    </Field>
                  </div>
                </div>

                <Field
                  as='textarea'
                  placeholder='Write a message'
                  className='border-[0.5px] border-black bg-white mt-8 h-48 rounded-sm w-full placeholder:text-black p-2'
                />
                <p className='text-lg text-gray-400'>
                  By submitting this form you consent to data processing and
                  communication in accordance with our{' '}
                  <span className='text-red font-semibold hover:text-[#0f1725]'><Link to="/privacy">privacy policy</Link></span>.
                </p>
                <div className='text-center md:text-left mt-12'>
                  <button className='bg-[#A0B350] hover:bg-[#0f1725] p-3 rounded-sm text-white w-full md:w-2/4 text-sm md:text-base'>
                    SUBMIT
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Hero;
