import React from 'react';
import {
  OilAndGasCard,
  OilAndGasHero,
} from '../../components/layout/OilAndGas';
import { ContactForm } from '../../components/layout/Contact';
import { OurBlog } from '../../components/layout/Home';
import { Tag } from '../../components/layout/Manufacturing';

const OilAndGasView = () => {
  return (
    <div>
      <OilAndGasHero />
      <Tag />
      <OilAndGasCard />
      <ContactForm />
      <OurBlog />
    </div>
  );
};

export default OilAndGasView;
