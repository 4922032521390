import React from 'react';
import { manufacturing } from '../../../assets/images';
import { ecoearth } from '../../../assets/icons';

const GlobalCitizen = () => {
  return (
    <div>
      <header
        className='h-[60vh] lg:h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${manufacturing})` }}
      >
        <div className='bg-green bg-opacity-70 w-screen h-[60vh] lg:h-[80vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <img src={ecoearth} alt='' className='h-28 w-28' />
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            Global Citizen
          </div>
        </div>
      </header>
    </div>
  );
};

export default GlobalCitizen;
