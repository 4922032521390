import React from 'react';

const Commitment = () => {
  return (
    <div className='text-lg text-center md:text-left flex flex-col gap-6 items-center justify-center px-5 sm:px-12 xl:px-64 py-16 md:py-24'>
      <p className='text-3xl md:text-4xl font-semibold tracking-wide text-center lg:text-left'>
        Our commitment to create a better world.
      </p>
      <p>
        Consistent with our vision to help create the products that build a
        better world, we are committed to building a better world by the way we
        innovate and operate. Tatabe’s ESG program strives to build strong
        communities, develop our team members in an inclusive and diverse
        culture, protect our environment, embrace strong governance practices
        and set similar expectations on our partners. We recognize that by
        improving outcomes for society and all of our stakeholders, we maximize
        our ability to achieve our strategic objectives and deliver long-term
        value for our shareholders. We have demonstrated this commitment since
        our founding in 2010; it is authentic and core to our culture and
        long-term success.
      </p>
      <p>
        A Tatabe leadership committee chaired by our Chief Administrative
        Officer (CAO), which includes membership of our CEO, CFO and COO,
        governs our ESG program. Our Board of Directors is also highly engaged
        in our ESG efforts and strategy. The Governance and Sustainability
        Committee of the board oversees the effectiveness of our ESG program,
        including ESG policies and initiatives, sustainability reporting and
        trends that could impact the Company’s business operations, performance,
        reputation and sustainable growth. In addition, our Compensation and
        Leadership Development Committee oversees our human capital strategy,
        including diversity and inclusion efforts and global compensation
        policies and philosophies, while our Audit Committee oversees the
        effectiveness of our internal controls over reporting, our
        whistle-blower reporting program and management and governance of
        information technology.
      </p>
      <p>
        In addition to our ESG governance, we are accustomed to establishing
        non-financial goals that are important to position Tatabe for
        sustainable long-term success. The Compensation and Leadership
        Development Committee works with Tatabe management to identify these
        goals that often comprise objectives under our short-term incentive
        compensation plan. In Fiscal Year 2022, for example, objectives under
        our executive short-term incentive plan include a 5% energy intensity
        reduction target across all manufacturing sites, expansion of our
        Employee Resource Groups, which help to foster an inclusive workplace,
        as well as continued enhancements and testing of our cybersecurity
        incident response plan.
      </p>
    </div>
  );
};

export default Commitment;
