import React from 'react';
import OilAndGasView from './OilAndGasView';
import Wrapper from '../../components/modules/wrapper/Wrapper';

export const OilAndGasContainer = () => {
  return (
    <Wrapper>
      <OilAndGasView />
    </Wrapper>
  );
};
