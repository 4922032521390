import React from 'react';
import TradeAndDistributionView from './TradeAndDistributionView';
import { Wrapper } from '../../components/modules/wrapper';

export const TradeAndDistributionContainer = () => {
  return (
    <Wrapper>
      <TradeAndDistributionView />
    </Wrapper>
  );
};
