import React from 'react';
import { sustainabilityHero } from '../../../assets/images';
import { globe } from '../../../assets/icons';

const SustainabilityHero = () => {
  return (
    <div>
      <header
        className='h-[80vh] bg-center bg-fixed bg-no-repeat bg-cover flex align-center justify-center w-full '
        style={{ backgroundImage: `url(${sustainabilityHero})` }}
      >
        <div className='bg-black bg-opacity-70 w-screen h-[80vh] flex flex-col gap-8 justify-center items-center px-6 sm:px-8 md:px-12 lg:px-56 '>
          <img src={globe} alt='' loading='lazy' />
          <div className='text-white font-bold md:font-semibold text-3xl md:text-6xl text-center md:leading-tight'>
            Building a better world by the way we innovate and operate.
          </div>
        </div>
      </header>
    </div>
  );
};

export default SustainabilityHero;
