export const HOME = '/';
export const ABOUT = '/about';
export const BUSINESSES = '/businesses';
export const CAREER = '/career';
export const CONTACT = '/contact';
export const MEDIA = '/media';
export const SUSTAINABILITY = '/sustainability';
export const IMPORTEXPORT = '/importexport';
export const LEADERSHIP = '/leadership';
export const LOCATIONS = '/locations';
export const NEWS = '/news';
export const LOGISTICS_SUPPLYCHAIN = '/logistics-supplychain';
export const OILANDGAS = '/oilandgas';
export const MANUFACTURING = '/manufacturing';
export const SUSTAINING_SERVICES = '/sustaining-services';
export const TRADE_DISTRIBUTION = '/trade-distribution';
export const PLASTIC = '/plastic';
export const SHIPPING = '/shipping';
export const TOMATO = '/tomato';
export const WHY = '/why';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const BLOG = '/blog';
export const DISTRIBUTION_MGT = '/distribution-management';
